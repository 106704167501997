var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":""}},[(!_vm.preview)?_c('v-card-title',[(_vm.$permissions.groupCanReadOutboundQuotes())?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","loading":_vm.luckyLoading},on:{"click":function($event){return _vm.imFeelingLuckyHandler()}}},[_vm._v("\n        "+_vm._s(_vm.$i18n.t('outbound.feelLucky'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.$permissions.groupCanReadOutboundQuotes())?_c('v-btn',{staticClass:"mr-2",on:{"click":function($event){return _vm.refreshData()}}},[_vm._v("\n        "+_vm._s(_vm.$i18n.t('refresh'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('v-switch',{staticClass:"ml-4 pt-3",attrs:{"inset":"","label":"Filters"},model:{value:(_vm.filtersToggle),callback:function ($$v) {_vm.filtersToggle=$$v},expression:"filtersToggle"}}),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.filtersToggle)?[_c('outbound-filter')]:[_c('data-table-search',{attrs:{"search-label":_vm.$i18n.t('search')},on:{"search":_vm.onSearch,"clear":_vm.onSearch}})]],2):_vm._e(),_vm._v(" "),_c('v-data-table',_vm._b({attrs:{"item-class":_vm.outboundRowColorMapper,"options":_vm.footerProps.options,"server-items-length":_vm.serverItemsLength ? _vm.serverItemsLength : _vm.items.length,"loader-height":"1","hide-default-footer":_vm.preview},on:{"update:options":function($event){return _vm.$set(_vm.footerProps, "options", $event)}},scopedSlots:_vm._u([{key:"item.callbackTime",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(_vm.$format.dateTime(value ? new Date(value) : '', _vm.$i18n.locale, { dateStyle: 'long', timeStyle: 'long' }))+"\n      ")]}},{key:"item.leadType",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(_vm.getLeadTypeString(value))+"\n      ")]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_vm._v("\n        "+_vm._s(_vm.$format.dateTime(new Date(value), _vm.$i18n.locale, { dateStyle: 'long', timeStyle: 'long' }))+"\n      ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.preview || _vm.controlSettings.disabledManualLeadPull)?[_vm._v("\n          "+_vm._s(item.qrn)+"\n        ")]:[_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.selectItemHandler(item)}}},[_vm._v("\n            "+_vm._s(item.qrn)+"\n          ")])]]}},{key:"footer.prepend",fn:function(){return [(_vm.refreshDate)?_c('div',{staticClass:"ml-2"},[_c('em',[_vm._v("Date refreshed: "+_vm._s(_vm.refreshDate))])]):_vm._e()]},proxy:true}],null,true)},'v-data-table',{
        headers: _vm.headers,
        items: _vm.items,
        loading: _vm.loading,
        footerProps: _vm.footerProps
      },false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }