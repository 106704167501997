//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default () {
        return false
      }
    },
    tooltip: {
      type: String,
      default () {
        return null
      }
    }
  }
}
