/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:b1083789-41f7-451d-b724-d098ff7b8f47",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_u2wmZQ3HL",
    "aws_user_pools_web_client_id": "61ipi5igjbjjqa85qh6fstnb7t",
    "oauth": {
        "domain": "webcentral-prod.auth.ca-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://webcentral.rates.ca/",
        "redirectSignOut": "https://webcentral.rates.ca/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 10,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://d4sqcfgvyzgczmpqyyofvdhmiq.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "webcentral-storage115523-prod",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
