import { render, staticRenderFns } from "./CallLogsDialog.vue?vue&type=template&id=1f9a60de&"
import script from "./CallLogsDialog.vue?vue&type=script&lang=js&"
export * from "./CallLogsDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CallLoggerForm: require('/builds/ratesdotca/rdc/webcentral/components/CallLoggerForm.vue').default,CallLoggerAlert: require('/builds/ratesdotca/rdc/webcentral/components/CallLoggerAlert.vue').default,DataTableDialogs: require('/builds/ratesdotca/rdc/webcentral/components/DataTableDialogs.vue').default,DataTableSearch: require('/builds/ratesdotca/rdc/webcentral/components/DataTableSearch.vue').default,DataTableAction: require('/builds/ratesdotca/rdc/webcentral/components/DataTableAction.vue').default,BaseDialog: require('/builds/ratesdotca/rdc/webcentral/components/BaseDialog.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCol,VDataTable,VFlex,VOverlay,VRow,VSpacer,VToolbar})
