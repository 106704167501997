//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    initialValue: {
      type: [String, Array],
      required: true
    },
    allowedDates: {
      type: Function,
      default: () => true
    }
  },
  data () {
    return {
      isMenuActive: false,
      date: this.initialValue,
      originalDate: this.initialValue
    }
  },
  computed: {
    dateText: {
      get () {
        return this.isRange ? this.date.join(' ~ ') : this.date
      },
      set () {
        this.date = this.isRange ? [] : ''
        this.emitUpdatedDateValue()
      }
    },
    isRange () {
      return this.date instanceof Array
    }
  },
  watch: {
    isMenuActive (val) {
      if (!val) {
        if (this.$refs.menu.originalValue) {
          this.originalDate = this.$refs.menu.originalValue
        } else {
          this.date = this.originalDate
        }
      }
    },
    date (val) {
      if (this.isRange && val.length === 2 && this.$dayjs(val[1]).isBefore(val[0])) {
        this.date = val.reverse()
      }
    }
  },
  methods: {
    clear () {
      this.date = this.originalDate = this.isRange ? [] : ''
    },
    save () {
      this.$refs.menu.save(this.date)
      this.emitUpdatedDateValue()
    },
    emitUpdatedDateValue () {
      this.$emit('change', { id: this.id, value: this.date })
    }
  }
}
