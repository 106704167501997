import Vue from 'vue'
import { Amplify, Hub } from 'aws-amplify'
import awsExports from '@/src/aws-exports'

awsExports.oauth.redirectSignIn = awsExports.oauth.redirectSignOut = `${process.env.BASEURL}/`

const awsConfig = { ...awsExports, ssr: process.env.APP_CONFIG.ssr }
Amplify.configure(awsConfig)

export default (context, inject) => {
  Hub.listen('auth', (data) => {
    /* if (context.$api && data.payload.event === 'signIn') {
      context.$api.registerClientToAgency()
    }
    if (data.payload.event.match(/(signOut|oAuthSignOut)/)) {
      context.store.dispatch('policies/clearPolicies')
      context.store.dispatch('profile/clearProfile')
    } */
  })

  context.$awsConfig = Vue.prototype.$awsConfig = Vue.$awsConfig = awsConfig
  inject('awsConfig', awsConfig)
}
