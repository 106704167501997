import { render, staticRenderFns } from "./DataTableDialogs.vue?vue&type=template&id=09b964d7&"
import script from "./DataTableDialogs.vue?vue&type=script&lang=js&"
export * from "./DataTableDialogs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseDialog: require('/builds/ratesdotca/rdc/webcentral/components/BaseDialog.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VRow})
