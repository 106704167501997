'use strict'

import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  new VuexPersistence({
    /* your options */
    modules: ['announcements', 'policies', 'profile', 'lead'],
    storage: window.sessionStorage
    // https://www.npmjs.com/package/vuex-persist#usage
  }).plugin(store)
}
