//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import {
  mdiMagnify,
  mdiCloseCircle
} from '@mdi/js'

export default {
  props: {
    searchLabel: {
      type: String,
      default: ''
    },
    initialValue: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      icons: {
        mdiMagnify,
        mdiCloseCircle
      },
      search: this.initialValue
    }
  }
}
