//
//
//
//
//
//

export default {
  props: {
    source: {
      type: String,
      required: true
    },
    wrapperClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageSrc () {
      const logos = {
        RATES: require('@@/assets/logos/ratesdotca_white.png'),
        LR: require('@@/assets/logos/lowestrates_white.png'),
        IH: require('@@/assets/logos/ih_white.png')
      }
      return Object.keys(logos).includes(this.source) ? logos[this.source] : this.source
    }
  }
}
