//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiOpenInNew } from '@mdi/js'
import dashboardMenuMixin from '@/mixins/dashboardMenu'

export default {
  mixins: [dashboardMenuMixin],
  data () {
    return {
      mdiOpenInNew,
      showNavDrawer: false
    }
  },
  watch: {
    showNavDrawer: {
      handler (value) {
        this.$root.$emit('nav-drawer-value-change', value)
      }
    }
  },
  mounted () {
    this.$root.$on('open-nav-drawer', () => {
      this.showNavDrawer = true
    })
  }
}
