export const state = () => ({
  loaded: false,
  items: []
})

export const mutations = {
  SET_ANNOUNCEMENTS (s, announcements) {
    s.items = announcements
    s.loaded = true
  },
  DELETE_ANNOUNCEMENT (s, announcementId) {
    s.items = s.items.filter(item => item.id !== announcementId)
  }
}

export const actions = {
  setAnnouncements ({ commit }, announcements) {
    commit('SET_ANNOUNCEMENTS', announcements)
  },
  deleteAnnouncement ({ commit }, announcementId) {
    commit('DELETE_ANNOUNCEMENT', announcementId)
  }
}
