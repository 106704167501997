const brandMap = {
  rates: 'Rates.ca',
  ih: 'IH.com',
  wl: 'Affiliate',
  lr: 'LR.ca'
}

const newLeadTypes = [
  ['qcp-outbound-scoop-', 'Unconv Scoop'],
  ['qcp-outbound-bolscoop-', 'Unconv Scoop BO'],
  ['qcp-outbound-scoop-nfp-', 'NFP Unconv Scoop'],
  ['qcp-outbound-scoop-otip-', 'OTIP Unconv Scoop Outbound'],
  ['qcp-outbound-scoop-hunter-', 'Hunter Unconv Scoop Outbound'],
  ['qcp-outbound-scoop-gallagher-', 'Gallagher Unconv Scoop'],
  ['qcp-outbound-combo-scoop-', 'Unconv Scoop Combo'],
  ['qcp-outbound-bolcombo-scoop-', 'Unconv Scoop Combo BO'],
  ['qcp-outbound-direct-', 'Unconv Directs'],
  ['qcp-outbound-boldirect-', 'Unconv Directs BO'],
  ['qcp-outbound-emailbroker-', 'Unconv 3rd Party Broker'],
  ['qcp-outbound-bolemailbroker-', 'Unconv 3rd Party Broker BO'],
  ['converted-outbound-scoop-', 'Converted Scoop'],
  ['converted-outbound-bolscoop-', 'Converted Scoop BO'],
  ['converted-outbound-scoop-nfp-', 'NFP Converted Scoop'],
  ['converted-outbound-scoop-otip-', 'OTIP Converted Scoop Outbound'],
  ['converted-outbound-scoop-hunter-', 'Hunter Converted Scoop Outbound'],
  ['converted-outbound-scoop-gallagher-', 'Gallagher Converted Scoop'],
  ['converted-outbound-combo-scoop-', 'Converted Scoop Combo'],
  ['converted-outbound-bolcombo-scoop-', 'Converted Scoop Combo BO'],
  ['converted-outbound-direct-', 'Converted Directs'],
  ['converted-outbound-boldirect-', 'Converted Directs BO'],
  ['converted-outbound-emailbroker-', 'Converted 3rd Party Broker'],
  ['converted-outbound-bolemailbroker-', 'Converted 3rd Party Broker BO']
]

const ratesLeadTypes = [
  ['qcp-outbound-scoop-caa-overflow-', 'CAA Unconv Scoop Overflow'],
  ['qcp-outbound-bolscoop-caa-overflow-', 'CAA Unconv Scoop Overflow BO'],
  ['converted-outbound-scoop-caa-overflow-', 'CAA Converted Scoop Overflow'],
  ['converted-outbound-bolscoop-caa-overflow-', 'CAA Converted Scoop Overflow BO'],
  ['qcp-outbound-scoop-ratesbot-', 'RB Unconv Scoop'],
  ['converted-outbound-scoop-ratesbot-', 'RB Converted Scoop']
]

module.exports = {
  brandMap,
  newLeadTypes,
  ratesLeadTypes
}
