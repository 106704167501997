const { brandMap, newLeadTypes, ratesLeadTypes } = require('@/src/lead-types.js')

export default {
  computed: {
    newLeadTypes () {
      const newOptions = []
      Object.keys(brandMap).forEach((brand) => {
        newLeadTypes.forEach((leadType) => {
          newOptions.push({
            value: `match::leadType.keyword::${leadType[0]}${brand}`,
            text: `${brandMap[brand]} ${leadType[1]}`
          })
        })
      })

      ratesLeadTypes.forEach((leadType) => {
        newOptions.push({
          value: `match::leadType.keyword::${leadType[0]}rates`,
          text: leadType[1]
        })
      })
      return newOptions
    }
  },
  methods: {
    getOutboundEligibleQuery (extraConditions = null) {
      const currentDate = new Date()
      const query = {
        bool: {
          must: [
            {
              exists: {
                field: 'callbackTime'
              }
            },
            {
              term: {
                eligible: true
              }
            },
            {
              range: {
                callbackTime: {
                  lte: currentDate.toISOString()
                }
              }
            }
          ]
        }
      }
      if (extraConditions) {
        query.bool.must.push(...extraConditions)
      }
      return JSON.stringify(query)
    },
    // Any changes to this function have to be duplicated in amplify/backend/function/webcentralOutboundResolver/src/index.js
    getLeadTypeString (leadType) {
      let leadTypeString = ''
      const sourceMap = {
        ih: 'IH.com',
        lr: 'LR.ca',
        rates: 'Rates.ca',
        scoop: 'Scoop.ca',
        nfp: 'NFP',
        wl: 'Affiliate',
        caa: 'CAA',
        ratesbot: 'RB',
        gallagher: 'Gallagher',
        onlia: 'Onlia',
        otip: 'OTIP',
        hunter: 'Hunter'
      }
      try {
        const leadTypeArr = leadType ? leadType.toLowerCase().split('-') : []
        if (leadTypeArr.length < 4) {
          throw new Error(`Incorrect lead type: ${leadType}`)
        }
        leadTypeString += sourceMap[leadTypeArr[3]] || leadTypeArr[3]
        if (leadTypeArr[0] === 'qcp') {
          leadTypeString += ' Unconv'
        } else if (leadTypeArr[0] === 'converted') {
          leadTypeString += ' Converted'
        }

        if (/scoop/.test(leadTypeArr[2])) {
          leadTypeString += ' Scoop'
        } else if (/direct/.test(leadTypeArr[2])) {
          leadTypeString += ' Directs'
        } else if (/emailbroker/.test(leadTypeArr[2])) {
          leadTypeString += ' 3rd Party Broker'
        } else if (/combo/.test(leadTypeArr[2])) {
          leadTypeString += ' Combo'
        }

        if (leadTypeArr[3] === 'otip') {
          leadTypeString += ' Outbound'
        } else if (leadTypeArr[3] === 'hunter') {
          leadTypeString += ' Outbound'
        } else if (/bol/.test(leadTypeArr[2])) {
          leadTypeString += ' BO'
        }

        if (/overflow/.test(leadTypeArr[4])) {
          leadTypeString += ' Overflow'
        }
      } catch (err) {
        console.error(err)
        leadTypeString = ''
      }
      return leadTypeString
    },
    // Any changes to this function have to be duplicated in amplify/backend/function/webcentralOutboundResolver/src/index.js
    getCallChannelByLeadType (data) {
      if (!data.leadType) {
        return null
      }
      if (data.leadType.match(/-outbound-[a-z]+-caa-overflow-/)) {
        return 'CAAOutbound'
      } else if (data.leadType.match(/ratesbot/)) {
        return data.leadType.match(/^converted-/) ? 'Ratesbot Scoop Converted' : 'Ratesbot Scoop Unconverted'
      } else if (data.leadType.match(/-unfulfilled-onlia$/)) {
        return 'OnliaOutbound'
      } else if (data.leadType.match(/(bolscoop|nfp|gallagher|combo)/)) {
        return `${this.getLeadTypeString(data.leadType)} Outbound`
      } else if (data.leadType.match(/(otip)/)) {
        return `${this.getLeadTypeString(data.leadType)}`
      } else if (data.leadType.match(/(hunter)/)) {
        return `${this.getLeadTypeString(data.leadType)}`
      } else if (data.leadType.match(/^(qcp[123]?|converted)-outbound-/)) {
        if (data.source === 'LR') {
          return data.qrn.match(/-LR$/) ? 'LR Outbound' : 'LR Whitelabel'
        } else if (!data.source || data.source === 'RATES') {
          return 'Rates.caOutbound'
        } else if (data.source === 'KTX') {
          return 'KTX Whitelabel'
        } else if (data.source === 'IH') {
          return 'InsuranceHotlineOutbound'
        }
      }
      return 'InsuranceHotlineOutbound'
    }
  }
}
