export default {
  methods: {
    getBrand (value) {
      if (!(typeof value === 'string' || value instanceof String)) {
        return null
      }
      const brands = {
        RATES: /\.rates\.ca$/,
        LR: /\.lowestrates\.ca$/,
        IH: /\.insurancehotline\.com$/
      }
      for (const [brand, regex] of Object.entries(brands)) {
        value = value.replace(/:[0-9]+$/, '')
        if (regex.test(value)) {
          return brand
        }
      }
      return Object.keys(brands).includes(value.toUpperCase()) ? value.toUpperCase() : null
    },
    getTableHeaders (headers, contentPrefix, align = 'left') {
      return headers.map((value) => {
        let text = ''
        if (value !== 'data-table-expand') {
          text = this.$i18n.te(`${contentPrefix}.${value}`) ? this.$i18n.t(`${contentPrefix}.${value}`) : value
        }
        return { text, value, align }
      })
    },
    getKanetixHours (dayId) {
      // Kanetix Call Center Hours
      try {
        const hours = [
          [null, null], // Sunday
          ['8:00', '20:00'], // Monday
          ['8:00', '20:00'], // Tuesday
          ['8:00', '20:00'], // Wednesday
          ['8:00', '20:00'], // Thursday
          ['8:00', '20:00'], // Friday
          ['8:30', '16:00'] // Saturday
        ]
        return dayId ? hours[dayId] : hours
      } catch (err) {
        console.error(err)
      }
      return null
    },
    getGenderFromCQ (cqVal) {
      if (cqVal === 'M') {
        return 'Male'
      } else if (cqVal === 'F') {
        return 'Female'
      }
      return null
    }
  }
}
