import notificationMixin from '@/mixins/notification'
const { controlCentreConfig } = require('../scripts/defaults/controlCentre.js')
export default {
  mixins: [notificationMixin],
  data () {
    return {
      loading: true,
      unlocked: false,
      scope: '',
      controls: {
        current: [],
        snapshot: [],
        isModified: false
      },
      history: {
        headers: ['json', 'updatedAt', 'user'],
        items: [],
        loading: false,
        options: { page: 1, itemsPerPage: 15 },
        serverItemsLength: 0
      }
    }
  },
  computed: {
    defaultSettings () {
      try {
        return controlCentreConfig.filter(i => i.scope === this.scope)[0].json
      } catch (err) {
        console.error(err)
      }
      return null
    }
  },
  watch: {
    'controls.current': {
      handler (val) {
        this.controls.isModified = this.controls.snapshot !== JSON.stringify(val)
      },
      deep: true
    }
  },
  methods: {
    async refreshHistory (options) {
      if (!this.$permissions.groupCanReadControlCentreConfig()) {
        return
      }
      this.history.loading = true
      try {
        this.history.options = options
        const response = await this.$api.searchControlCentreConfig({
          from: (options.page - 1) * options.itemsPerPage,
          size: options.itemsPerPage,
          query: this.searchQuery(`${this.scope}_LOG`),
          sort: JSON.stringify({ updatedAt: 'desc' })
        })
        this.history.items = response.hits
        this.history.serverItemsLength = response.total
      } catch (err) {
        console.error(err)
      }
      this.history.loading = false
    },
    async unlock () {
      if (!this.$permissions.groupCanUpdateControlCentreConfig()) {
        return
      }
      this.loading = true
      try {
        const response = await this.$api.lockControlCentreConfig(this.controls.snapshot.id)
        if (response.status) {
          this.controls.isModified = false
          this.unlocked = true
        }
        this.setNotification(response.message, 5000)
      } catch (err) {
        console.error(err)
        this.setNotification(this.$i18n.t('controlCenter.notifications.failed'), 5000)
      }
      this.loading = false
    },
    async save (event) {
      if (!this.$permissions.groupCanUpdateControlCentreConfig()) {
        return
      }
      this.loading = true
      try {
        this.unlocked = false
        if (event === 'cancel') {
          this.setNotification(this.$i18n.t('controlCenter.notifications.canceled'), 5000)
        } else {
          const json = JSON.stringify(event === 'default' ? this.defaultSettings : this.controls.current)
          const response = await this.$api.updateControlCentreConfig(this.controls.snapshot.id, this.scope, json)
          this.setNotification(response.message, 5000)
        }
      } catch (err) {
        console.error(err)
        this.setNotification(this.$i18n.t('controlCenter.notifications.failed'), 5000)
      }
      await this.refreshData()
      await this.refreshHistory(this.history.options)
      this.loading = false
    },
    searchQuery (scope) {
      return JSON.stringify({
        bool: {
          must: [
            { match: { scope } }
          ]
        }
      })
    },
    async getSettings (scope) {
      try {
        const response = await this.$api.searchControlCentreConfig({ from: 0, size: 1, query: this.searchQuery(scope) })
        return response?.total ? response.hits[0] : null
      } catch (err) {
        console.error(err)
      }
      return null
    }
  }
}
