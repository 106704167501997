import Vue from 'vue'

const getDefaultState = () => {
  return {
    auto: {},
    home: {},
    commercialAuto: {},
    commercialProperty: {}
  }
}

export const state = () => getDefaultState()

export const mutations = {
  SET_POLICIES (s, { policyType, policies }) {
    s[policyType] = { ...policies }
  },
  CLEAR_POLICIES (s) {
    Object.assign(s, getDefaultState())
  }
}

export const actions = {
  async setPolicies ({ commit, state: currentState }, forceRequest = false) {
    // Populate policies if every policyType is empty or forceRequest
    if (forceRequest || Object.values(currentState).every(o => Object.keys(o).length === 0)) {
      const policyTypeMap = {
        auto: {
          key: 'auto',
          policies: {}
        },
        home: {
          key: 'home',
          policies: {}
        },
        commercial_auto: {
          key: 'commercialAuto',
          policies: {}
        },
        commercial_property: {
          key: 'commercialProperty',
          policies: {}
        }
      }
      const res = await Vue.prototype.$api.getPolicies()
      if (res && res.length) {
        res.forEach((policy) => {
          if (Object.keys(policyTypeMap).includes(policy.policy_type_code)) {
            policyTypeMap[policy.policy_type_code].policies[policy.uniq_policy] = Vue.prototype.$format.keysToCamel(policy)
          }
        })
        Object.values(policyTypeMap).forEach((policy) => {
          commit('SET_POLICIES', {
            policyType: policy.key,
            policies: policy.policies
          })
        })
      }
    }
  },
  clearPolicies ({ commit }) {
    commit('CLEAR_POLICIES')
  }
}
