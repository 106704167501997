import { render, staticRenderFns } from "./DatePicker.vue?vue&type=template&id=645d2fa4&scoped=true&"
import script from "./DatePicker.vue?vue&type=script&lang=js&"
export * from "./DatePicker.vue?vue&type=script&lang=js&"
import style0 from "./DatePicker.vue?vue&type=style&index=0&id=645d2fa4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645d2fa4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VDatePicker,VMenu,VSpacer,VTextField})
