/* global BroadcastChannel */
import Vue from 'vue'

const broadcast = {
  connectChannel: new BroadcastChannel('connectChannel')
}

export default (context, inject) => {
  context.$broadcast = Vue.prototype.$broadcast = Vue.$broadcast = broadcast
  inject('broadcast', broadcast)
}
