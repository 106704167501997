/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $email: String!
    $groups: [String]!
    $domains: [String]!
    $custom__domains: String
  ) {
    createUser(
      email: $email
      groups: $groups
      domains: $domains
      custom__domains: $custom__domains
    ) {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__domains
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $username: String!
    $email: String!
    $groups: [String]
    $domains: [String]!
    $custom__domains: String
  ) {
    updateUser(
      username: $username
      email: $email
      groups: $groups
      domains: $domains
      custom__domains: $custom__domains
    ) {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__domains
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($username: String!) {
    deleteUser(username: $username)
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($username: String!) {
    disableUser(username: $username)
  }
`;
export const enableUser = /* GraphQL */ `
  mutation EnableUser($username: String!) {
    enableUser(username: $username)
  }
`;
export const signOutUser = /* GraphQL */ `
  mutation SignOutUser($username: String!) {
    signOutUser(username: $username)
  }
`;
export const resetUserPassword = /* GraphQL */ `
  mutation ResetUserPassword($username: String!) {
    resetUserPassword(username: $username)
  }
`;
export const resendTempPassword = /* GraphQL */ `
  mutation ResendTempPassword($username: String!) {
    resendTempPassword(username: $username) {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__domains
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $name: String!
    $description: String
    $precedence: Int!
  ) {
    createGroup(
      name: $name
      description: $description
      precedence: $precedence
    ) {
      name
      description
      createDate
      lastModifiedDate
      precedence
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $name: String!
    $description: String
    $precedence: Int!
  ) {
    updateGroup(
      name: $name
      description: $description
      precedence: $precedence
    ) {
      name
      description
      createDate
      lastModifiedDate
      precedence
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($name: String!) {
    deleteGroup(name: $name)
  }
`;
export const createGroupPermission = /* GraphQL */ `
  mutation CreateGroupPermission(
    $group: String!
    $scope: String!
    $permissions: [String]
  ) {
    createGroupPermission(
      group: $group
      scope: $scope
      permissions: $permissions
    ) {
      id
      group
      scope
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const updateGroupPermission = /* GraphQL */ `
  mutation UpdateGroupPermission(
    $id: String!
    $group: String!
    $scope: String!
    $permissions: [String]
  ) {
    updateGroupPermission(
      id: $id
      group: $group
      scope: $scope
      permissions: $permissions
    ) {
      id
      group
      scope
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroupPermission = /* GraphQL */ `
  mutation DeleteGroupPermission($id: String!) {
    deleteGroupPermission(id: $id) {
      id
      group
      scope
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const createDomain = /* GraphQL */ `
  mutation CreateDomain($name: String!, $description: String) {
    createDomain(name: $name, description: $description) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateDomain = /* GraphQL */ `
  mutation UpdateDomain($id: String!, $name: String!, $description: String) {
    updateDomain(id: $id, name: $name, description: $description) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteDomain = /* GraphQL */ `
  mutation DeleteDomain($id: String!) {
    deleteDomain(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const forgetDevice = /* GraphQL */ `
  mutation ForgetDevice($deviceKey: String!) {
    forgetDevice(deviceKey: $deviceKey)
  }
`;
export const postEvent = /* GraphQL */ `
  mutation PostEvent($scopeId: String!, $eventType: String!) {
    postEvent(scopeId: $scopeId, eventType: $eventType) {
      scopeId
      eventType
    }
  }
`;
export const createAnnouncement = /* GraphQL */ `
  mutation CreateAnnouncement(
    $message: String!
    $order: Int
    $enabled: Boolean
  ) {
    createAnnouncement(message: $message, order: $order, enabled: $enabled) {
      id
      message
      order
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const updateAnnouncement = /* GraphQL */ `
  mutation UpdateAnnouncement(
    $id: String!
    $message: String!
    $order: Int
    $enabled: Boolean
  ) {
    updateAnnouncement(
      id: $id
      message: $message
      order: $order
      enabled: $enabled
    ) {
      id
      message
      order
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const deleteAnnouncement = /* GraphQL */ `
  mutation DeleteAnnouncement($id: String!) {
    deleteAnnouncement(id: $id) {
      id
      message
      order
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $question: String!
    $answer: String!
    $category: String!
    $order: Int
  ) {
    createFaq(
      question: $question
      answer: $answer
      category: $category
      order: $order
    ) {
      id
      question
      answer
      category
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $id: String!
    $question: String!
    $answer: String!
    $category: String!
    $order: Int
  ) {
    updateFaq(
      id: $id
      question: $question
      answer: $answer
      category: $category
      order: $order
    ) {
      id
      question
      answer
      category
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq($id: String!) {
    deleteFaq(id: $id) {
      id
      question
      answer
      category
      order
      createdAt
      updatedAt
    }
  }
`;
export const createLibraryLink = /* GraphQL */ `
  mutation CreateLibraryLink($linkId: String!, $libraryKey: String!) {
    createLibraryLink(linkId: $linkId, libraryKey: $libraryKey) {
      id
      linkId
      libraryKey
      createdAt
      updatedAt
    }
  }
`;
export const updateLibraryLink = /* GraphQL */ `
  mutation UpdateLibraryLink(
    $id: String!
    $linkId: String!
    $libraryKey: String!
  ) {
    updateLibraryLink(id: $id, linkId: $linkId, libraryKey: $libraryKey) {
      id
      linkId
      libraryKey
      createdAt
      updatedAt
    }
  }
`;
export const deleteLibraryLink = /* GraphQL */ `
  mutation DeleteLibraryLink($id: String!) {
    deleteLibraryLink(id: $id) {
      id
      linkId
      libraryKey
      createdAt
      updatedAt
    }
  }
`;
export const createTransfer = /* GraphQL */ `
  mutation CreateTransfer($payload: createTransferPayload!) {
    createTransfer(payload: $payload)
  }
`;
export const updateTransfer = /* GraphQL */ `
  mutation UpdateTransfer($payload: updateTransferPayload!) {
    updateTransfer(payload: $payload)
  }
`;
export const uploadEzleadsTransfer = /* GraphQL */ `
  mutation UploadEzleadsTransfer(
    $source: Source!
    $payload: uploadEzleadsTransferPayload!
  ) {
    uploadEzleadsTransfer(source: $source, payload: $payload)
  }
`;
export const deliverLeadTransfer = /* GraphQL */ `
  mutation DeliverLeadTransfer(
    $source: Source!
    $payload: deliverLeadTransferPayload!
  ) {
    deliverLeadTransfer(source: $source, payload: $payload)
  }
`;
export const deleteTransfer = /* GraphQL */ `
  mutation DeleteTransfer($transferId: Int!, $source: Source!) {
    deleteTransfer(transferId: $transferId, source: $source)
  }
`;
export const resendEmail = /* GraphQL */ `
  mutation ResendEmail(
    $leadId: String!
    $quoter: String!
    $source: Source!
    $email: String!
  ) {
    resendEmail(
      leadId: $leadId
      quoter: $quoter
      source: $source
      email: $email
    )
  }
`;
export const createTransferSessionAttributes = /* GraphQL */ `
  mutation CreateTransferSessionAttributes(
    $quoteRefId: String!
    $transferId: String!
    $source: String!
    $quoter: String!
    $agentId: String
  ) {
    createTransferSessionAttributes(
      quoteRefId: $quoteRefId
      transferId: $transferId
      source: $source
      quoter: $quoter
      agentId: $agentId
    )
  }
`;
export const createControlCentreConfig = /* GraphQL */ `
  mutation CreateControlCentreConfig(
    $scope: ControlCentreScope!
    $json: AWSJSON!
  ) {
    createControlCentreConfig(scope: $scope, json: $json)
  }
`;
export const updateControlCentreConfig = /* GraphQL */ `
  mutation UpdateControlCentreConfig(
    $id: String!
    $scope: ControlCentreScope!
    $json: AWSJSON!
    $queryLimits: String
  ) {
    updateControlCentreConfig(
      id: $id
      scope: $scope
      json: $json
      queryLimits: $queryLimits
    )
  }
`;
export const lockControlCentreConfig = /* GraphQL */ `
  mutation LockControlCentreConfig($id: String!) {
    lockControlCentreConfig(id: $id)
  }
`;
export const deleteControlCentreConfig = /* GraphQL */ `
  mutation DeleteControlCentreConfig(
    $id: String!
    $scope: ControlCentreScope!
  ) {
    deleteControlCentreConfig(id: $id, scope: $scope)
  }
`;
export const addLeadToOutbound = /* GraphQL */ `
  mutation AddLeadToOutbound($payload: AddToOutboundInput!) {
    addLeadToOutbound(payload: $payload)
  }
`;
export const addLatestCallLogToOutbound = /* GraphQL */ `
  mutation AddLatestCallLogToOutbound(
    $qrn: String!
    $source: String!
    $attemptFilter: Int
  ) {
    addLatestCallLogToOutbound(
      qrn: $qrn
      source: $source
      attemptFilter: $attemptFilter
    )
  }
`;
export const closeOutboundLeads = /* GraphQL */ `
  mutation CloseOutboundLeads(
    $qrn: String!
    $source: Source!
    $quoter: Quoter
    $leadId: Int
  ) {
    closeOutboundLeads(
      qrn: $qrn
      source: $source
      quoter: $quoter
      leadId: $leadId
    )
  }
`;
export const markOutboundLeadConverted = /* GraphQL */ `
  mutation MarkOutboundLeadConverted(
    $payload: MarkOutboundLeadConvertedInput!
  ) {
    markOutboundLeadConverted(payload: $payload)
  }
`;
export const reassignLeadOutbound = /* GraphQL */ `
  mutation ReassignLeadOutbound(
    $id: String!
    $lastHandledBy: String!
    $callbackTime: AWSDateTime!
  ) {
    reassignLeadOutbound(
      id: $id
      lastHandledBy: $lastHandledBy
      callbackTime: $callbackTime
    )
  }
`;
export const reinsertLeadToOutbound = /* GraphQL */ `
  mutation ReinsertLeadToOutbound(
    $qrn: String!
    $source: Source!
    $callbackTime: AWSDateTime!
    $lastHandledBy: String
    $attemptFilter: Int
  ) {
    reinsertLeadToOutbound(
      qrn: $qrn
      source: $source
      callbackTime: $callbackTime
      lastHandledBy: $lastHandledBy
      attemptFilter: $attemptFilter
    )
  }
`;
export const cancelOutboundCallLog = /* GraphQL */ `
  mutation CancelOutboundCallLog(
    $id: String!
    $qrn: String
    $source: Source
    $attempt: Int!
  ) {
    cancelOutboundCallLog(
      id: $id
      qrn: $qrn
      source: $source
      attempt: $attempt
    )
  }
`;
export const removeLeadFromOutbound = /* GraphQL */ `
  mutation RemoveLeadFromOutbound($payload: RemoveFromOutboundInput!) {
    removeLeadFromOutbound(payload: $payload)
  }
`;
export const imFeelingLucky = /* GraphQL */ `
  mutation ImFeelingLucky($searchQuery: String!) {
    imFeelingLucky(searchQuery: $searchQuery)
  }
`;
export const updateRcDialerOutbound = /* GraphQL */ `
  mutation UpdateRcDialerOutbound($payload: updateRcDialerOutboundPayload!) {
    updateRcDialerOutbound(payload: $payload)
  }
`;
export const syncAttemptsOutbound = /* GraphQL */ `
  mutation SyncAttemptsOutbound($qrn: String!) {
    syncAttemptsOutbound(qrn: $qrn)
  }
`;
export const syncLatestCallLogToOutbound = /* GraphQL */ `
  mutation SyncLatestCallLogToOutbound(
    $id: String!
    $attempts: Int!
    $qrn: String!
    $source: String!
  ) {
    syncLatestCallLogToOutbound(
      id: $id
      attempts: $attempts
      qrn: $qrn
      source: $source
    )
  }
`;
export const createExcludeOutbound = /* GraphQL */ `
  mutation CreateExcludeOutbound($type: ExcludeOutboundType!, $value: String!) {
    createExcludeOutbound(type: $type, value: $value)
  }
`;
export const updateExcludeOutbound = /* GraphQL */ `
  mutation UpdateExcludeOutbound(
    $id: String!
    $type: ExcludeOutboundType!
    $value: String!
  ) {
    updateExcludeOutbound(id: $id, type: $type, value: $value)
  }
`;
export const deleteExcludeOutbound = /* GraphQL */ `
  mutation DeleteExcludeOutbound($id: String!) {
    deleteExcludeOutbound(id: $id)
  }
`;
export const createCallLog = /* GraphQL */ `
  mutation CreateCallLog(
    $id: String!
    $attempt: Int!
    $callLog: CallLogInput!
    $leadId: String
    $source: Source
    $qrn: String
    $quoter: Quoter
  ) {
    createCallLog(
      id: $id
      attempt: $attempt
      callLog: $callLog
      leadId: $leadId
      source: $source
      qrn: $qrn
      quoter: $quoter
    )
  }
`;
export const updateCallLog = /* GraphQL */ `
  mutation UpdateCallLog(
    $id: String!
    $attempt: Int!
    $callLog: CallLogInput!
    $leadId: String
    $source: Source
    $qrn: String
    $quoter: Quoter
  ) {
    updateCallLog(
      id: $id
      attempt: $attempt
      callLog: $callLog
      leadId: $leadId
      source: $source
      qrn: $qrn
      quoter: $quoter
    )
  }
`;
export const deleteCallLog = /* GraphQL */ `
  mutation DeleteCallLog($id: String!, $attempt: Int!) {
    deleteCallLog(id: $id, attempt: $attempt)
  }
`;
export const refreshCallLogIndex = /* GraphQL */ `
  mutation RefreshCallLogIndex($id: String!, $attempt: Int!) {
    refreshCallLogIndex(id: $id, attempt: $attempt)
  }
`;
export const reIndexBrokerList = /* GraphQL */ `
  mutation ReIndexBrokerList {
    reIndexBrokerList
  }
`;
export const reIndexSupplierList = /* GraphQL */ `
  mutation ReIndexSupplierList {
    reIndexSupplierList
  }
`;
export const createJsonLog = /* GraphQL */ `
  mutation CreateJsonLog(
    $scope: String!
    $event: String!
    $source: String!
    $log: AWSJSON
  ) {
    createJsonLog(scope: $scope, event: $event, source: $source, log: $log)
  }
`;
export const createBMOData = /* GraphQL */ `
  mutation CreateBMOData($payload: [csvData]!, $source: Source!) {
    createBMOData(payload: $payload, source: $source)
  }
`;
export const createFintelData = /* GraphQL */ `
  mutation CreateFintelData($payload: [fintelData]!, $source: Source!) {
    createFintelData(payload: $payload, source: $source)
  }
`;
export const updateDisputeStatus = /* GraphQL */ `
  mutation UpdateDisputeStatus($payload: disputeData!, $source: Source!) {
    updateDisputeStatus(payload: $payload, source: $source)
  }
`;
export const confirmGiftCardApplications = /* GraphQL */ `
  mutation ConfirmGiftCardApplications(
    $data: [giftCardApp]!
    $additionalData: [String]!
    $source: Source!
    $type: String
  ) {
    confirmGiftCardApplications(
      data: $data
      additionalData: $additionalData
      source: $source
      type: $type
    )
  }
`;
export const completeQualtricsQuoteEvent = /* GraphQL */ `
  mutation CompleteQualtricsQuoteEvent(
    $data: completeQualtricsQuoteEventInput!
  ) {
    completeQualtricsQuoteEvent(data: $data)
  }
`;
export const startPhoneCall = /* GraphQL */ `
  mutation StartPhoneCall(
    $agentId: String
    $sessionId: String
    $phoneNumber: String!
    $brand: String!
  ) {
    startPhoneCall(
      agentId: $agentId
      sessionId: $sessionId
      phoneNumber: $phoneNumber
      brand: $brand
    )
  }
`;
export const endPhoneCall = /* GraphQL */ `
  mutation EndPhoneCall($sessionId: String, $agentId: String) {
    endPhoneCall(sessionId: $sessionId, agentId: $agentId)
  }
`;
export const createUnfulfilledLead = /* GraphQL */ `
  mutation CreateUnfulfilledLead($data: String!, $source: Source!) {
    createUnfulfilledLead(data: $data, source: $source)
  }
`;
export const changeOutboundLeadQrn = /* GraphQL */ `
  mutation ChangeOutboundLeadQrn(
    $qrnUpdated: String!
    $qrnOriginal: String!
    $source: Source!
  ) {
    changeOutboundLeadQrn(
      qrnUpdated: $qrnUpdated
      qrnOriginal: $qrnOriginal
      source: $source
    )
  }
`;
export const createOutboundResolverLog = /* GraphQL */ `
  mutation CreateOutboundResolverLog($type: String, $data: String!) {
    createOutboundResolverLog(type: $type, data: $data)
  }
`;
export const reinsertFetchedByRcLeadsToOutbound = /* GraphQL */ `
  mutation ReinsertFetchedByRcLeadsToOutbound {
    reinsertFetchedByRcLeadsToOutbound
  }
`;
