//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    alert () {
      try {
        const alert = this.$store.state.callLogger.alert
        if (alert.type && (alert.id || alert.message)) {
          return {
            type: alert.type,
            message: alert.id ? this.$i18n.t(`callLogger.alerts.${alert.id}`, { time: this.$dayjs().format('HH:mm:ss') }) : alert.message
          }
        }
      } catch (err) {
        console.error(err)
      }
      return {}
    }
  }
}
