
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    customRow: {
      type: Boolean,
      default: false
    }
  }
}
