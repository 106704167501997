/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUsers = /* GraphQL */ `
  query GetUsers {
    getUsers {
      username
      email
      status
      enabled
      groups
      userCreateDate
      userLastModifiedDate
      custom__domains
    }
  }
`;
export const getAvailableGroups = /* GraphQL */ `
  query GetAvailableGroups {
    getAvailableGroups {
      name
      description
      createDate
      lastModifiedDate
      precedence
    }
  }
`;
export const getGroupPermissions = /* GraphQL */ `
  query GetGroupPermissions {
    getGroupPermissions {
      id
      group
      scope
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const getDomains = /* GraphQL */ `
  query GetDomains {
    getDomains {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers($from: Int!, $size: Int!, $query: String, $sort: String) {
    searchUsers(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        username
        email
        status
        enabled
        groups
        userCreateDate
        userLastModifiedDate
        custom__domains
      }
    }
  }
`;
export const searchGroups = /* GraphQL */ `
  query SearchGroups($from: Int!, $size: Int!, $query: String, $sort: String) {
    searchGroups(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        name
        description
        createDate
        lastModifiedDate
        precedence
      }
    }
  }
`;
export const searchDomains = /* GraphQL */ `
  query SearchDomains($from: Int!, $size: Int!, $query: String, $sort: String) {
    searchDomains(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        name
        description
        createdAt
        updatedAt
      }
    }
  }
`;
export const searchGroupPermissions = /* GraphQL */ `
  query SearchGroupPermissions(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchGroupPermissions(
      from: $from
      size: $size
      query: $query
      sort: $sort
    ) {
      total
      hits {
        id
        group
        scope
        permissions
        createdAt
        updatedAt
      }
    }
  }
`;
export const getAnnouncements = /* GraphQL */ `
  query GetAnnouncements {
    getAnnouncements {
      id
      message
      order
      enabled
      createdAt
      updatedAt
    }
  }
`;
export const searchAnnouncements = /* GraphQL */ `
  query SearchAnnouncements(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchAnnouncements(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        message
        order
        enabled
        createdAt
        updatedAt
      }
    }
  }
`;
export const getFaqs = /* GraphQL */ `
  query GetFaqs {
    getFaqs {
      id
      question
      answer
      category
      order
      createdAt
      updatedAt
    }
  }
`;
export const searchFaqs = /* GraphQL */ `
  query SearchFaqs($from: Int!, $size: Int!, $query: String, $sort: String) {
    searchFaqs(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        question
        answer
        category
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const getLibraryLinks = /* GraphQL */ `
  query GetLibraryLinks {
    getLibraryLinks {
      id
      linkId
      libraryKey
      createdAt
      updatedAt
    }
  }
`;
export const searchLibraryLinks = /* GraphQL */ `
  query SearchLibraryLinks(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchLibraryLinks(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        linkId
        libraryKey
        createdAt
        updatedAt
      }
    }
  }
`;
export const getQuote = /* GraphQL */ `
  query GetQuote($id: String!) {
    getQuote(id: $id)
  }
`;
export const getLeads = /* GraphQL */ `
  query GetLeads($csrInput: String!, $startDate: String, $endDate: String) {
    getLeads(csrInput: $csrInput, startDate: $startDate, endDate: $endDate)
  }
`;
export const getLead = /* GraphQL */ `
  query GetLead($id: String!, $quoter: String!, $source: Source!) {
    getLead(id: $id, quoter: $quoter, source: $source)
  }
`;
export const getLeadByQrn = /* GraphQL */ `
  query GetLeadByQrn(
    $qrn: String!
    $quoter: String!
    $source: Source
    $guid: String
  ) {
    getLeadByQrn(qrn: $qrn, quoter: $quoter, source: $source, guid: $guid)
  }
`;
export const getBrokerList = /* GraphQL */ `
  query GetBrokerList {
    getBrokerList
  }
`;
export const searchBrokerList = /* GraphQL */ `
  query SearchBrokerList(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchBrokerList(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        first_name
        last_name
        role
        profile
        email
        irca_status
        createdAt
        updatedAt
      }
    }
  }
`;
export const getTransfersBySource = /* GraphQL */ `
  query GetTransfersBySource($source: Source!, $payload: transfersSearch!) {
    getTransfersBySource(source: $source, payload: $payload)
  }
`;
export const getTransfers = /* GraphQL */ `
  query GetTransfers($payload: transfersSearch!) {
    getTransfers(payload: $payload)
  }
`;
export const getControlCentreConfig = /* GraphQL */ `
  query GetControlCentreConfig($id: String!) {
    getControlCentreConfig(id: $id) {
      id
      scope
      lockedBy
      lockedDateTime
      json
      logType
      user
      queryLimits
      createdAt
      updatedAt
    }
  }
`;
export const getAllControlCentreConfig = /* GraphQL */ `
  query GetAllControlCentreConfig {
    getAllControlCentreConfig {
      id
      scope
      lockedBy
      lockedDateTime
      json
      logType
      user
      queryLimits
      createdAt
      updatedAt
    }
  }
`;
export const searchControlCentreConfig = /* GraphQL */ `
  query SearchControlCentreConfig(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchControlCentreConfig(
      from: $from
      size: $size
      query: $query
      sort: $sort
    ) {
      total
      hits {
        id
        scope
        lockedBy
        lockedDateTime
        json
        logType
        user
        queryLimits
        createdAt
        updatedAt
      }
    }
  }
`;
export const getExcludeOutbound = /* GraphQL */ `
  query GetExcludeOutbound {
    getExcludeOutbound {
      id
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const searchExcludeOutbound = /* GraphQL */ `
  query SearchExcludeOutbound(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchExcludeOutbound(
      from: $from
      size: $size
      query: $query
      sort: $sort
    ) {
      total
      hits {
        id
        type
        value
        createdAt
        updatedAt
      }
    }
  }
`;
export const searchCallLogs = /* GraphQL */ `
  query SearchCallLogs(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
    $search_after: [String]
  ) {
    searchCallLogs(
      from: $from
      size: $size
      query: $query
      sort: $sort
      search_after: $search_after
    ) {
      total
      hits {
        id
        leadId
        source
        qrn
        quoter
        attempt
        csr
        lastHandledBy
        callLog
        createdAt
        updatedAt
      }
      searchAfter
    }
  }
`;
export const searchOutbound = /* GraphQL */ `
  query SearchOutbound(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchOutbound(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        id
        leadId
        source
        qrn
        quoter
        leadType
        callbackTime
        lastHandledBy
        companyId
        premium
        firstName
        lastName
        phone
        email
        attempts
        abandoned
        message
        eligible
        province
        callLog
        cgiKickoutMessage
        addedToRcQueueAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const searchOutboundByKeyValue = /* GraphQL */ `
  query SearchOutboundByKeyValue($key: String!, $value: String!) {
    searchOutboundByKeyValue(key: $key, value: $value)
  }
`;
export const searchLeadGroupsByCsr = /* GraphQL */ `
  query SearchLeadGroupsByCsr($query: String) {
    searchLeadGroupsByCsr(query: $query) {
      key
      doc_count
    }
  }
`;
export const searchRealtimeOutboundLeads = /* GraphQL */ `
  query SearchRealtimeOutboundLeads(
    $quoter: Quoter!
    $startDateTime: String!
    $endDateTime: String!
  ) {
    searchRealtimeOutboundLeads(
      quoter: $quoter
      startDateTime: $startDateTime
      endDateTime: $endDateTime
    ) {
      key
      doc_count
    }
  }
`;
export const getRcDialerOutbound = /* GraphQL */ `
  query GetRcDialerOutbound {
    getRcDialerOutbound
  }
`;
export const getBubblePrioritySort = /* GraphQL */ `
  query GetBubblePrioritySort($prioritySettings: String) {
    getBubblePrioritySort(prioritySettings: $prioritySettings)
  }
`;
export const getPromoFilters = /* GraphQL */ `
  query GetPromoFilters($source: Source!) {
    getPromoFilters(source: $source)
  }
`;
export const getPromoApplications = /* GraphQL */ `
  query GetPromoApplications($filters: [String]!, $source: Source!) {
    getPromoApplications(filters: $filters, source: $source)
  }
`;
export const getPromoDisputeApplications = /* GraphQL */ `
  query GetPromoDisputeApplications($filters: [String]!, $source: Source!) {
    getPromoDisputeApplications(filters: $filters, source: $source)
  }
`;
export const getGiftCardApplications = /* GraphQL */ `
  query GetGiftCardApplications($filters: [String]!, $source: Source!) {
    getGiftCardApplications(filters: $filters, source: $source)
  }
`;
export const downloadCSVFile = /* GraphQL */ `
  query DownloadCSVFile($filters: [String]!, $source: Source!, $type: String!) {
    downloadCSVFile(filters: $filters, source: $source, type: $type)
  }
`;
export const downloadUnprocessedGiftCards = /* GraphQL */ `
  query DownloadUnprocessedGiftCards(
    $filters: [String]!
    $source: Source!
    $type: String!
  ) {
    downloadUnprocessedGiftCards(
      filters: $filters
      source: $source
      type: $type
    )
  }
`;
export const sendDataToActOn = /* GraphQL */ `
  query SendDataToActOn($formId: String!, $formData: String!) {
    sendDataToActOn(formId: $formId, formData: $formData)
  }
`;
export const getTrustPilotInvitationLink = /* GraphQL */ `
  query GetTrustPilotInvitationLink(
    $source: Source!
    $data: trustPilotInvitationLinkInput!
  ) {
    getTrustPilotInvitationLink(source: $source, data: $data)
  }
`;
export const getTransferSessionAttributes = /* GraphQL */ `
  query GetTransferSessionAttributes($agentId: String) {
    getTransferSessionAttributes(agentId: $agentId)
  }
`;
export const getActivePhoneCall = /* GraphQL */ `
  query GetActivePhoneCall($agentId: String) {
    getActivePhoneCall(agentId: $agentId)
  }
`;
export const getAgentDetails = /* GraphQL */ `
  query GetAgentDetails {
    getAgentDetails
  }
`;
export const searchSuppliers = /* GraphQL */ `
  query SearchSuppliers(
    $from: Int!
    $size: Int!
    $query: String
    $sort: String
  ) {
    searchSuppliers(from: $from, size: $size, query: $query, sort: $sort) {
      total
      hits {
        code
        name
      }
    }
  }
`;
