const { brandMap, newLeadTypes, ratesLeadTypes } = require('../../src/lead-types.js')

function getLeadTypes () {
  const options = []
  Object.keys(brandMap).forEach((brand) => {
    newLeadTypes.forEach((leadType) => {
      options.push({ op: '=', value: `${leadType[0]}${brand}` })
    })
  })

  ratesLeadTypes.forEach((leadType) => {
    options.push({ op: '=', value: `${leadType[0]}rates` })
  })
  return options
}

const controlCentreConfig = [
  {
    scope: 'ADMIN_SETTINGS',
    json: {
      openHours: {
        test: '07:00 - 22:00'
      },
      statHolidays: {}
    }
  },
  {
    scope: 'BROKER_TOGGLES',
    json: {
      CIC: {
        broker: 'inssolutions',
        brokerList: [
          {
            value: 'inssolutions',
            name: 'KTX'
          },
          {
            value: 'buckleytransfer',
            name: 'Buckleys'
          }
        ]
      },
      PEM: {
        brokerList: [
          {
            value: 'inssolutions',
            name: 'KTX'
          },
          {
            name: 'Buckleys',
            value: 'buckleytransfer'
          }
        ],
        broker: 'inssolutions'
      },
      DOC: {
        broker: 'inssolutions',
        brokerList: [
          {
            name: 'KTX',
            value: 'inssolutions'
          },
          {
            value: 'buckleytransfer',
            name: 'Buckleys'
          }
        ]
      },
      HAL: {
        brokerList: [
          {
            value: 'inssolutions',
            name: 'KTX'
          },
          {
            value: 'buckleytransfer',
            name: 'Buckleys'
          }
        ],
        broker: 'inssolutions'
      }
    }
  },
  {
    scope: 'OUTBOUND_CONTROL',
    json: {
      AB: {
        commercial_auto: {
          outbound: 100,
          companies: [
            {
              cid: 'DOC',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'GA',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'SGI',
              sid: 'inssolutions',
              limit: 100
            }
          ]
        },
        moto: {
          outbound: 100,
          companies: [
            {
              cid: 'ECGL',
              sid: 'inssolutions',
              limit: 100
            }
          ]
        },
        auto: {
          outbound: 100,
          companies: [
            {
              cid: 'AVD',
              sid: 'AVD',
              limit: 100
            },
            {
              cid: 'DOC',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'ECON',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'GA',
              sid: 'GA',
              limit: 100
            },
            {
              cid: 'IH',
              sid: 'IH',
              limit: 0
            },
            {
              cid: 'PEA',
              sid: 'PEA',
              limit: 100
            },
            {
              cid: 'PEM',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'MILL',
              sid: 'mill',
              limit: 100
            },
            {
              cid: 'WAWA',
              sid: 'WAWA',
              limit: 100
            }
          ]
        },
        property: {
          outbound: 100,
          Home: {
            companies: [
              {
                cid: 'APOLLO',
                sid: 'APOLLO',
                limit: 100
              },
              {
                cid: 'BCIC',
                sid: 'BCIC',
                limit: 100
              },
              {
                cid: 'DOC',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'DUUO',
                sid: 'duuo',
                limit: 100
              },
              {
                cid: 'ECON',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GA',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'MAX',
                sid: 'MAX',
                limit: 100
              },
              {
                cid: 'PEM',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'PORT',
                sid: 'PORT',
                limit: 100
              },
              {
                cid: 'SGI',
                sid: 'SGI',
                limit: 100
              },
              {
                cid: 'sqone',
                sid: 'sqone',
                limit: 100
              },
              {
                cid: 'TDI',
                sid: 'tdi',
                limit: 100
              }
            ]
          },
          Condominium: {
            companies: [
              {
                cid: 'APOLLO',
                sid: 'APOLLO',
                limit: 100
              },
              {
                cid: 'BCIC',
                sid: 'BCIC',
                limit: 100
              },
              {
                cid: 'DOC',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'DUUO',
                sid: 'duuo',
                limit: 100
              },
              {
                cid: 'ECON',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GA',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'MAX',
                sid: 'MAX',
                limit: 100
              },
              {
                cid: 'PEM',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'PORT',
                sid: 'PORT',
                limit: 100
              },
              {
                cid: 'SGI',
                sid: 'SGI',
                limit: 100
              },
              {
                cid: 'sqone',
                sid: 'sqone',
                limit: 100
              },
              {
                cid: 'TDI',
                sid: 'tdi',
                limit: 100
              }
            ]
          },
          Tenant: {
            companies: [
              {
                cid: 'APOLLO',
                sid: 'APOLLO',
                limit: 100
              },
              {
                cid: 'BCIC',
                sid: 'BCIC',
                limit: 100
              },
              {
                cid: 'DOC',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'DUUO',
                sid: 'duuo',
                limit: 100
              },
              {
                cid: 'ECON',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GA',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'MAX',
                sid: 'MAX',
                limit: 100
              },
              {
                cid: 'PEM',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'PORT',
                sid: 'PORT',
                limit: 100
              },
              {
                cid: 'SGI',
                sid: 'SGI',
                limit: 100
              },
              {
                cid: 'sqone',
                sid: 'sqone',
                limit: 100
              },
              {
                cid: 'TDI',
                sid: 'tdi',
                limit: 100
              }
            ]
          }
        }
      },
      ON: {
        commercial_auto: {
          outbound: 100,
          companies: [
            {
              cid: 'DOC',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'ECGL',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'ECON',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'GA',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'GORE',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'WAWA',
              sid: 'inssolutions',
              limit: 100
            }
          ]
        },
        moto: {
          outbound: 100,
          companies: [
            {
              cid: 'ECGM',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'GA',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'PEM',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'SGI',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'WAWA',
              sid: 'inssolutions',
              limit: 100
            }
          ]
        },
        auto: {
          outbound: 100,
          ktx_queue: 60,
          companies: [
            {
              cid: 'AVD',
              sid: 'AVD',
              limit: 0
            },
            {
              cid: 'CAA',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'CAA3',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'CAA_MYPACE',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'CER',
              sid: 'certas',
              limit: 100
            },
            {
              cid: 'CER25',
              sid: 'certas',
              limit: 100
            },
            {
              cid: 'CIC',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'CICapi',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'COA',
              sid: 'inssolutions',
              limit: 100
            },
            {
              sid: 'inssolutions',
              cid: 'DOC',
              limit: 100
            },
            {
              sid: 'inssolutions',
              limit: 100,
              cid: 'DOC3'
            },
            {
              cid: 'DOCapi',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'ECGL',
              sid: 'inssolutions',
              limit: 100
            },
            {
              cid: 'ECON',
              limit: 100,
              sid: 'inssolutions'
            },
            {
              sid: 'inssolutions',
              limit: 100,
              cid: 'GA'
            },
            {
              sid: 'inssolutions',
              limit: 100,
              cid: 'GA2'
            },
            {
              sid: 'inssolutions',
              cid: 'GORE',
              limit: 100
            },
            {
              cid: 'IH',
              sid: 'IH',
              limit: 0
            },
            {
              cid: 'JEV',
              limit: 100,
              sid: 'JEV'
            },
            {
              cid: 'NATF',
              limit: 100,
              sid: 'NATF'
            },
            {
              cid: 'NWFM',
              limit: 100,
              sid: 'NWFM'
            },
            {
              limit: 100,
              cid: 'MILL',
              sid: 'MILL'
            },
            {
              cid: 'ONLIA',
              limit: 100,
              sid: 'ONLIA'
            },
            {
              cid: 'OTIP',
              limit: 100,
              sid: 'otip'
            },
            {
              cid: 'PAF',
              limit: 100,
              sid: 'inssolutions'
            },
            {
              sid: 'inssolutions',
              cid: 'PEEL',
              limit: 100
            },
            {
              sid: 'inssolutions',
              cid: 'PEM',
              limit: 100
            },
            {
              sid: 'inssolutions',
              cid: 'SGI',
              limit: 100
            },
            {
              limit: 100,
              cid: 'SONNET',
              sid: 'sonnet'
            },
            {
              sid: 'inssolutions',
              limit: 100,
              cid: 'WAWA'
            },
            {
              sid: 'inssolutions',
              limit: 100,
              cid: 'ZENI'
            }
          ]
        },
        property: {
          outbound: 100,
          Home: {
            companies: [
              {
                cid: 'APOLLO',
                sid: 'APOLLO',
                limit: 100
              },
              {
                cid: 'AVD',
                sid: 'AVD',
                limit: 100
              },
              {
                cid: 'CAA',
                sid: 'CAA',
                limit: 100
              },
              {
                cid: 'DOC',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'DUUO',
                sid: 'duuo',
                limit: 100
              },
              {
                cid: 'ECON',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GA',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GORE',
                sid: 'GORE',
                limit: 100
              },
              {
                cid: 'PEEL',
                sid: 'PEEL',
                limit: 100
              },
              {
                cid: 'PEM',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'PMAR',
                sid: 'PMAR',
                limit: 100
              },
              {
                cid: 'SGI',
                sid: 'SGI',
                limit: 100
              },
              {
                cid: 'sqone',
                sid: 'sqone',
                limit: 100
              },
              {
                cid: 'TDI',
                sid: 'tdi',
                limit: 100
              }
            ]
          },
          Condominium: {
            companies: [
              {
                cid: 'APOLLO',
                sid: 'APOLLO',
                limit: 100
              },
              {
                cid: 'AVD',
                sid: 'AVD',
                limit: 100
              },
              {
                cid: 'CAA',
                sid: 'CAA',
                limit: 100
              },
              {
                cid: 'DOC',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'DUUO',
                sid: 'duuo',
                limit: 100
              },
              {
                cid: 'ECON',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GA',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GORE',
                sid: 'GORE',
                limit: 100
              },
              {
                cid: 'PEEL',
                sid: 'PEEL',
                limit: 100
              },
              {
                cid: 'PEM',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'PMAR',
                sid: 'PMAR',
                limit: 100
              },
              {
                cid: 'SGI',
                sid: 'SGI',
                limit: 100
              },
              {
                cid: 'sqone',
                sid: 'sqone',
                limit: 100
              },
              {
                cid: 'TDI',
                sid: 'tdi',
                limit: 100
              }
            ]
          },
          Tenant: {
            companies: [
              {
                cid: 'APOLLO',
                sid: 'APOLLO',
                limit: 100
              },
              {
                cid: 'AVD',
                sid: 'AVD',
                limit: 100
              },
              {
                cid: 'CAA',
                sid: 'CAA',
                limit: 100
              },
              {
                cid: 'DOC',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'DUUO',
                sid: 'duuo',
                limit: 100
              },
              {
                cid: 'ECON',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GA',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'GORE',
                sid: 'GORE',
                limit: 100
              },
              {
                cid: 'PEEL',
                sid: 'PEEL',
                limit: 100
              },
              {
                cid: 'PEM',
                sid: 'inssolutions',
                limit: 100
              },
              {
                cid: 'PMAR',
                sid: 'PMAR',
                limit: 100
              },
              {
                cid: 'SGI',
                sid: 'SGI',
                limit: 100
              },
              {
                cid: 'sqone',
                sid: 'sqone',
                limit: 100
              },
              {
                cid: 'TDI',
                sid: 'tdi',
                limit: 100
              }
            ]
          }
        }
      },
      PQ: {
        property: {
          outbound: 0,
          Home: {
            companies: [
              {
                limit: 0,
                cid: 'SONNET',
                sid: 'sonnet'
              },
              {
                limit: 0,
                cid: 'TDI',
                sid: 'tdi'
              }
            ]
          },
          Condominium: {
            companies: [
              {
                sid: 'sonnet',
                cid: 'SONNET',
                limit: 0
              },
              {
                cid: 'TDI',
                limit: 0,
                sid: 'tdi'
              }
            ]
          },
          Tenant: {
            companies: [
              {
                sid: 'sonnet',
                limit: 0,
                cid: 'SONNET'
              },
              {
                cid: 'TDI',
                limit: 0,
                sid: 'tdi'
              }
            ]
          }
        }
      }
    }
  },
  {
    scope: 'BUBBLE_PRIORITY',
    json: {
      settings: [
        {
          field: 'lastHandledBy',
          cases: [
            { op: '=', value: 'Connected' },
            { op: '=', value: 'Unassigned' },
            { op: '=', value: 'Not-connected' }
          ]
        },
        {
          field: 'qrn',
          sort: 'ASC'
        },
        {
          field: 'callbackTime',
          sort: 'DESC'
        },
        {
          field: 'quoter',
          cases: [
            { op: '=', value: 'AUTO' },
            { op: '=', value: 'HOME' },
            { op: '=', value: 'MOTO' },
            { op: '=', value: 'COMMERCIAL_AUTO' },
            { op: '=', value: 'COMMERCIAL_PROPERTY' },
            { op: '=', value: 'IRCA' }
          ]
        },
        {
          field: 'leadType',
          cases: getLeadTypes()
        },
        {
          field: 'province',
          cases: [
            { op: '=', value: 'ON' },
            { op: '=', value: 'AB' }
          ]
        },
        {
          field: 'attempts',
          cases: [
            { op: '=', value: '0' },
            { op: '=', value: '1' },
            { op: '=', value: '2' },
            { op: '=', value: '3' }
          ]
        },
        {
          field: 'premium',
          cases: [
            { op: '<', value: '1500' },
            { op: 'between', value: '1500 and 2499' },
            { op: 'between', value: '2500 and 3499' },
            { op: 'between', value: '3500 and 4999' },
            { op: '>', value: '4999' }
          ]
        }
      ]
    }
  },
  {
    scope: 'NEXT_LEAD',
    json: {
      settings: [
        {
          key: 'maxUnallocatedThreshold',
          val: 156,
          slider: {
            range: [1, 1000],
            step: 1
          }
        },
        {
          key: 'maxAllocatedThreshold',
          val: 63,
          slider: {
            step: 1,
            range: [1, 1000]
          }
        },
        {
          key: 'averageCallTime',
          val: 4,
          slider: {
            step: 1,
            range: [1, 20]
          }
        },
        {
          key: 'disabledManualLeadPull',
          switch: true,
          val: 0
        }
      ]
    }
  },
  {
    scope: 'PROGRESSIVE_DIALER_CONTROL',
    json: {
      brandsAllowedForRC: {
        auto: [],
        home: []
      },
      brokersAllowedForRC: {
        auto: [],
        home: []
      },
      hideOutboundBubble: 0,
      reinsertLeadTime: 0,
      maxLeads: 0
    }
  }
]

module.exports = {
  controlCentreConfig
}
