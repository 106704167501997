//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import callLoggerFormMixin from '@/mixins/callLoggerForm'

export default {
  mixins: [callLoggerFormMixin],
  props: {
    readonlyFields: {
      type: Array,
      default: () => []
    },
    languageField: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentLog: this.currentLogData,
      callLoggerOriginalTime: '',
      isMenuActive: {
        callbackTime: false
      }
    }
  },
  computed: {
    languageItems () {
      return this.$i18n.t('callLogger.fields.language.items')
    },
    callTypeItems () {
      const itemsKey = this.isOutbound ? 'outboundItems' : 'inboundItems'
      return this.$store.state.callLogger.currentLog.callLog.callChannel ? this.$i18n.t(`callLogger.fields.callType.${itemsKey}`) : []
    },
    showCallBackInput () {
      return this.$store.state.callLogger.currentLog.callLog.callDetails === 'Call Back required'
    },
    callDetailsItems () {
      let itemsKey
      switch (this.$store.state.callLogger.currentLog.callLog.callType) {
        case 'Connected-inbound':
        case 'Connected-outbound':
          itemsKey = 'connectedItems'
          break
        case 'Not-connected':
          itemsKey = 'notConnectedItems'
          break
        case 'Lead':
        case 'Transfer':
          itemsKey = 'leadItems'
          break
        case 'Service Call':
          itemsKey = 'serviceCallItems'
          break
        case 'Service Call Sonnet':
          itemsKey = 'sonnetItems'
          break
      }
      return this.$store.state.callLogger.currentLog.callLog.callChannel && itemsKey ? this.$i18n.t(`callLogger.fields.callDetails.${itemsKey}`) : []
    },
    formFields () {
      return [
        {
          id: 'callLog',
          idx: 'callChannel',
          type: 'select',
          display: true,
          rules: this.formRules(['required']),
          items: this.$i18n.t('callLogger.fields.callChannel.items')
        },
        {
          id: 'callLogger',
          idx: 'qrn',
          type: 'text-field',
          display: true,
          rules: this.formRules(this.$store.state.callLogger.currentLog.callLog.callChannel === 'Notes' ? [] : ['required']),
          readonly: this.readonlyFields.includes('qrn')
        },
        {
          id: 'callLog',
          idx: 'profileModified',
          type: 'select',
          display: true,
          rules: this.formRules(['required']),
          items: this.$i18n.t('callLogger.fields.profileModified.items')
        },
        {
          id: 'callLogger',
          idx: 'attempt',
          type: 'text-field',
          display: this.$store.state.callLogger.currentLog.callLogger.attempt,
          readonly: true
        },
        {
          id: 'callLog',
          idx: 'callType',
          type: 'select',
          display: true,
          rules: this.formRules(['required']),
          items: this.callTypeItems
        },
        {
          id: 'callLog',
          idx: 'callDetails',
          type: 'select',
          display: true,
          rules: this.formRules(['required']),
          items: this.callDetailsItems
        },
        {
          id: 'callLog',
          idx: 'callback',
          type: 'select',
          display: this.showCallBackInput,
          rules: this.formRules(['required']),
          items: this.$i18n.t('callLogger.fields.callback.items')
        },
        {
          id: 'callLog',
          idx: 'callbackDate',
          type: 'date-picker',
          display: this.$store.state.callLogger.currentLog.callLog.callback === 'Custom',
          rules: this.formRules(['required'])
        },
        {
          id: 'callLog',
          idx: 'callbackTime',
          type: 'time-picker',
          display: this.$store.state.callLogger.currentLog.callLog.callback === 'Custom',
          rules: this.formRules(['required', 'onlyFutureTime'])
        },
        {
          id: 'callLog',
          idx: 'notes',
          type: 'text-field',
          display: true,
          rules: this.formRules(['required'])
        },
        {
          id: 'callLog',
          idx: 'firstName',
          type: 'text-field',
          display: true,
          rules: this.formRules(['required'])
        },
        {
          id: 'callLog',
          idx: 'lastName',
          type: 'text-field',
          display: true,
          rules: this.formRules(['required'])
        },
        {
          id: 'callLog',
          idx: 'phone',
          type: 'text-field',
          display: true,
          rules: this.formRules(this.$store.state.callLogger.currentLog.callLog.callChannel === 'Notes' ? ['onlyDigits', 'phoneFormat'] : ['required', 'onlyDigits', 'phoneFormat'])
        },
        {
          id: 'callLog',
          idx: 'kPlus',
          type: 'select',
          display: !/outbound/i.test(this.$store.state.callLogger.currentLog.callLog.callChannel),
          rules: this.formRules(['required']),
          items: this.$i18n.t('callLogger.fields.kPlus.items')
        },
        {
          id: 'callLog',
          idx: 'additionalDetails',
          type: 'select',
          display: this.$store.state.callLogger.currentLog.callLog.callDetails === 'Not interested',
          rules: this.formRules(['required']),
          items: this.$i18n.t('callLogger.fields.additionalDetails.items')
        },
        {
          id: 'callLog',
          idx: 'language',
          type: 'select',
          display: this.languageField,
          rules: this.formRules(['required']),
          items: this.languageItems
        }
      ]
    }
  },
  watch: {
    'isMenuActive.callbackTime': {
      handler (val) {
        if (!val) {
          if (this.$refs.callbackTime[0].originalValue) {
            this.callLoggerOriginalTime = this.$refs.callbackTime[0].originalValue
          } else {
            this.$store.dispatch('callLogger/updateCurrentCallLog', { callbackTime: this.callLoggerOriginalTime })
          }
        }
      }
    },
    showCallBackInput: {
      handler (show) {
        if (!show) {
          this.$store.dispatch('callLogger/updateCurrentCallLog', {
            callback: '',
            callbackDate: '',
            callbackTime: ''
          })
        }
      }
    },
    '$store.state.callLogger.currentLog.callLog': {
      handler (newCallLog) {
        if (!this.callDetailsItems.includes(newCallLog.callDetails)) {
          this.$store.dispatch('callLogger/updateCurrentCallLog', { callDetails: '' })
        }
        if (!this.callTypeItems.includes(newCallLog.callType)) {
          this.$store.dispatch('callLogger/updateCurrentCallLog', { callType: '' })
        }
        if (!this.languageItems.map(itm => itm.value).includes(newCallLog.language)) {
          this.$store.dispatch('callLogger/updateCurrentCallLog', { language: '' })
        }
        if (newCallLog.callChannel === 'Sonnet Unfulfilled OB') {
          this.$store.dispatch('callLogger/updateCurrentCallLog', { profileModified: false })
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    allowedDates (val) {
      // Statutory holidays also have to be excluded
      return !/^(0)$/.test(this.$dayjs(val).day())
    },
    getFieldValue (id, idx) {
      return this.$store.state.callLogger.currentLog[id][idx]
    },
    submit () {
      this.$emit('submit')
    }
  }
}
