import {
  mdiMagnify,
  mdiDelete,
  mdiCheckCircleOutline,
  mdiCancel,
  mdiLockReset,
  mdiCloseCircle,
  mdiPlusCircleOutline,
  mdiSendLockOutline,
  mdiAccount,
  mdiAccountMinus,
  mdiUpload,
  mdiDownload,
  mdiCircleEditOutline
} from '@mdi/js'
export default {
  async fetch () {
    if (!this.bypassMixinFetch) {
      await this.refreshData()
    }
  },
  fetchOnServer: false,
  data () {
    return {
      autoRefreshIntervalId: '',
      autoRefreshRate: null,
      refreshDate: null,
      bypassMixinFetch: false,
      icons: {
        mdiMagnify,
        mdiDelete,
        mdiCheckCircleOutline,
        mdiCancel,
        mdiLockReset,
        mdiCloseCircle,
        mdiPlusCircleOutline,
        mdiSendLockOutline,
        mdiAccount,
        mdiAccountMinus,
        mdiUpload,
        mdiDownload,
        mdiCircleEditOutline
      },
      items: [],
      loading: false,
      search: '',
      dialogs: {
        create: {
          show: false,
          error: false
        },
        update: {
          show: false,
          error: false
        },
        delete: {
          show: false,
          error: false
        },
        generic: {
          show: false,
          error: false
        }
      },
      forms: {},
      resetData: {},
      footerProps: {
        itemsPerPageOptions: [1, 10, 25, 50],
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: []
        }
      },
      serverItemsLength: -1
    }
  },
  methods: {
    showDialog (actionType, dataType, data) {
      this.dialogs[actionType].show = true
      this.forms[dataType] = Object.assign({}, data)
      this.resetForm(dataType)
      if (actionType.match(/^(update|delete|generic)$/)) {
        this.forms[dataType] = Object.assign({}, data)
      }
    },
    showGenericDialog (actionType, dataType, data, args = []) {
      // Dialog Labels
      this.dialogs.generic.title = this.$i18n.t(`${this.page}.dialogs.${actionType}.title`)
      this.dialogs.generic.confirmMessage = this.$i18n.t(`${this.page}.dialogs.${actionType}.confirmMessage`)
      this.dialogs.generic.closeAriaLabel = this.$i18n.t(`${this.page}.dialogs.${actionType}.closeAriaLabel`)

      // Add action and args to generic obj, these will be sent back from dialog though emit event
      this.dialogs.generic.actionType = actionType
      this.dialogs.generic.actionArgs = args

      this.showDialog('generic', dataType, data)
    },
    genericAction ({ actionType, actionArgs = [] }) {
      this[actionType](...actionArgs)
    },
    hideDialog (actionType) {
      const actionTypes = ['create', 'update', 'create-update', 'delete']
      actionTypes.forEach((at) => {
        if (this.$refs[`${at}-form`]) {
          this.$refs[`${at}-form`].resetValidation()
        }
      })
    },
    createItem () {
      this.dialogs.create.show = false
    },
    updateItem () {
      this.dialogs.update.show = false
    },
    deleteItem () {
      this.dialogs.delete.show = false
    },
    resetForm (dataType) {
      this.forms[dataType] = Object.assign({}, this.resetData[dataType])
    },
    async onSearch (search) {
      this.search = search
      this.footerProps.options.page = 1
      await this.refreshData(true)
    }
  },
  watch: {
    'dialogs.create.show': {
      handler (newValue) {
        if (!newValue) {
          this.hideDialog('create')
          this.dialogs.create.error = false
        }
      }
    },
    'dialogs.update.show': {
      handler (newValue) {
        if (!newValue) {
          this.hideDialog('create')
          this.dialogs.update.error = false
        }
      }
    },
    'dialogs.delete.show': {
      handler (newValue) {
        if (!newValue) {
          this.hideDialog('create')
          this.dialogs.delete.error = false
        }
      }
    },
    'footerProps.options': {
      async handler (nVal, oVal) {
        const fields = ['itemsPerPage', 'page', 'sortBy', 'sortDesc']
        const optionsChanged = fields.reduce((acc, cur) => {
          return acc || nVal[cur] !== oVal[cur]
        }, false)
        if (optionsChanged) {
          await this.refreshData(true)
        }
      },
      deep: true
    },
    autoRefreshRate: {
      handler (val) {
        if (this.autoRefreshIntervalId) {
          clearInterval(this.autoRefreshIntervalId)
        }
        if (val) {
          this.autoRefreshIntervalId = setInterval(async () => {
            await this.refreshData(true)
          }, this.autoRefreshRate)
        }
      }
    }
  }
}
