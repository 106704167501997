/* eslint-disable no-unused-vars */
import { datadogRum } from '@datadog/browser-rum'
import Vue from 'vue'
import awsExports from '@/src/aws-exports'

const appSyncURL = new URL(awsExports.aws_appsync_graphqlEndpoint)
const appSyncWssURL = new URL(awsExports.aws_appsync_graphqlEndpoint.replace(/appsync-api/, 'appsync-realtime-api').replace(/^https/, 'wss'))
// const apiGwURL = new URL(awsExports.aws_cloud_logic_custom[0].endpoint)

class DatadgoService {
  setAttributes (obj) {
    datadogRum.setUser({
      id: obj.lookupCodes,
      name: obj.email,
      email: obj.email,
      groups: obj.groups
    })
  }

  removeUser () {
    datadogRum.removeUser()
  }
}

export default (context, inject) => {
  const datadogService = new DatadgoService(context.auth)
  context.$dd = Vue.prototype.$dd = Vue.$dd = datadogService
  inject('dd', datadogService)

  datadogRum.init({
    applicationId: context.$config.DD_CONFIG.APPLICATION_ID,
    clientToken: context.$config.DD_CONFIG.CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: context.$config.DD_CONFIG.SERVICE,
    env: context.$config.DD_CONFIG.ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // Use same value as DD_CONFIG.ENV, but we should use proper semantic versioning
    version: context.$config.DD_CONFIG.ENV,
    sampleRate: 100,
    trackInteractions: true,
    // Enable tracing
    allowedTracingOrigins: [
      `https://cognito-idp.${awsExports.aws_cognito_region}.amazonaws.com`,
      `https://cognito-identity.${awsExports.aws_cognito_region}.amazonaws.com`,
      // `https://${awsExports.aws_user_files_s3_bucket}.s3.${awsExports.aws_user_files_s3_bucket_region}.amazonaws.com`,
      `${appSyncURL.protocol}//${appSyncURL.host}`,
      `${appSyncWssURL.protocol}//${appSyncWssURL.host}`/* ,
      `${apiGwURL.protocol}//${apiGwURL.host}` */
    ]
  })
}
