export default async ({ redirect, route, store, app }) => {
  // this usually mean 404, return so it can be handled by layouts/error.vue
  if (!route.name) {
    return
  }

  await store.dispatch('auth/load', { bypassCache: true })
  if (store.state.auth.isAuthenticated) {
    store.dispatch('callLogger/loadAgentDetails')
  }
  const reNonAuthRoutes = /^(login|register|forgot-password|confirm-reset-password|sso)___(en|fr)$/
  if (!store.state.auth.isAuthenticated) {
    if (!route.name.match(reNonAuthRoutes)) {
      redirect({ name: `login___${app.i18n.locale}` })
    }
  } else if (route.name.match(reNonAuthRoutes)) {
    redirect({ name: `index___${app.i18n.locale}` })
  }
}
