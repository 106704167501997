import Vue from 'vue'

// this is used to encapsulate
class AuthService {
  constructor (store) {
    this.$store = store
  }

  get isAuthenticated () {
    return this.$store.state.auth.isAuthenticated
  }

  get user () {
    return this.$store.state.auth.user
  }

  get ssoEmailAddress () {
    const [provider, email] = this.$store.state.auth.user.username.split('_')
    return provider === 'RatesDotCaProvider' ? email : null
  }

  get email () {
    if (!this.user) { return }
    return this.user.attributes.email
  }

  get emailVerified () {
    if (!this.user) { return }
    return this.user.attributes.email_verified
  }

  get phoneNumber () {
    if (!this.user) { return }
    return this.user.attributes.phone_number
  }

  get phoneNumberVerified () {
    if (!this.user) { return }
    return this.user.attributes.phone_number_verified
  }

  get mfa () {
    if (!this.user) { return }
    return this.user.preferredMFA === 'SMS_MFA'
  }

  get fullName () {
    if (!this.user ||
        !this.user.attributes.family_name ||
        !this.user.attributes.given_name) { return }
    return `${this.user.attributes.given_name} ${this.user.attributes.family_name}`
  }

  get lookupCodes () {
    if (!this.user ||
        !this.user.attributes['custom:lookup_codes']) { return }
    return this.user.attributes['custom:lookup_codes']
  }

  get domains () {
    if (!this.user ||
        !this.user.attributes['custom:domains']) { return }
    return this.user.attributes['custom:domains']
  }

  get groups () {
    if (!this.user ||
        !this.user.signInUserSession ||
        !this.user.signInUserSession.idToken ||
        !this.user.signInUserSession.idToken.payload) { return }
    return this.user.signInUserSession.idToken.payload['cognito:groups']
  }

  get providerType () {
    if (!this.user ||
      !this.user.signInUserSession ||
      !this.user.signInUserSession.idToken ||
      !this.user.signInUserSession.idToken.payload ||
      !this.user.signInUserSession.idToken.payload.identities ||
      !this.user.signInUserSession.idToken.payload.identities[0]) { return }
    return this.user.signInUserSession.idToken.payload.identities[0].providerType
  }

  get isCustomer () {
    return (this.groups && this.groups.length === 1 && this.groups[0] === 'User')
  }

  get isViewAs () {
    return (this.lookupCodes && this.groups && this.groups.find(e => e === 'Admin' || e === 'Broker'))
  }

  get isUserAmbassadorOnly () {
    return this.groups.includes('Ambassador') && !/Admin/.test(this.groups)
  }

  get isUserScoopBrokerOnly () {
    return this.groups.includes('ScoopUser') && !/Admin/.test(this.groups)
  }

  accessChecks (scope, reqs) {
    const _accessChecks = (s, r) => {
      // scope permissions need to have permissions, reqs permissions
      const _permissions = () => {
        if (!this.user ||
          !this.user.signInUserSession ||
          !this.user.signInUserSession.idToken ||
          !this.user.signInUserSession.idToken.payload) { return }
        return JSON.parse(this.user.signInUserSession.idToken.payload['custom:permissions'])
      }
      const permissions = _permissions()
      if (Array.isArray(permissions[s])) {
        return r.reduce((acc, cur) => {
          return acc && permissions[s].find(p => p === cur)
        }, true)
      }
      return false
    }
    if (this.groups && Array.isArray(reqs)) {
      if (this.groups.find(g => g === 'Admin')) {
      // Admin should have access to everything
      // access to permissions are going to be Admin only
        return true
      }
      if (scope) {
        return _accessChecks(scope, reqs)
      }
    }
    return false
  }
}

export default async (context, inject) => {
  const authService = new AuthService(context.store)
  context.$auth = Vue.prototype.$auth = Vue.$auth = authService
  inject('auth', authService)
  await context.store.dispatch('auth/load', { bypassCache: true })
}
