import Vue from 'vue'
const uuidv4 = require('uuid/v4')

const defaultCallLogger = {
  id: '',
  leadId: '',
  source: '',
  qrn: '',
  quoter: '',
  attempt: 0
}

const defaultCallLog = {
  firstName: '',
  lastName: '',
  phone: '',
  callType: '',
  callChannel: '',
  callDetails: '',
  additionalDetails: '',
  notes: '',
  callback: '',
  callbackDate: '',
  callbackTime: '',
  language: '',
  saved: false,
  kPlus: null,
  profileModified: null,
  postalCode: '',
  dateOfBirth: null,
  gender: '',
  drivers: null,
  vehicles: null,
  cancelled: false
}

const defaultState = {
  toggle: false,
  miniDrawer: true,
  loading: false,
  lock: [],
  alert: {},
  showCallLogsDialog: false,
  search: {},
  currentLog: {
    callLogger: { ...defaultCallLogger },
    callLog: { ...defaultCallLog },
    originalCallBackTime: null
  },
  callLogs: [],
  incomingCall: {},
  agent: {},
  callSession: {},
  initiateCall: false
}

const getCallLog = async ({ qrn, leadId, quoter, source, attempt }) => {
  let callLog = null
  const searchParams = {
    from: 0,
    size: 1,
    query: JSON.stringify({
      bool: {
        must: [
          {
            bool: {
              should: [
                { match: { 'leadId.keyword': leadId } },
                { match: { 'qrn.keyword': qrn } }
              ]
            }
          },
          { match: { 'quoter.keyword': quoter.toUpperCase() } },
          { match: { 'source.keyword': source.toUpperCase() } },
          { match: { attempt } }
        ]
      }
    })
  }
  try {
    const res = await Vue.prototype.$api.searchCallLogs(searchParams)
    if (res?.hits?.length > 0) {
      callLog = res.hits[0]
      callLog = Object.assign(callLog, { callLog: JSON.parse(callLog.callLog) })
    }
  } catch (err) {
    console.error(err)
  }
  return callLog
}

const getCallLogs = async ({ qrn, leadId, quoter, source }) => {
  let allLogs = []
  let activeLogs = []
  const searchParams = {
    from: 0,
    size: 50,
    query: JSON.stringify({
      bool: {
        must: [
          { match: { 'qrn.keyword': qrn } },
          { match: { 'quoter.keyword': quoter.toUpperCase() } },
          { match: { 'source.keyword': source.toUpperCase() } }
        ]
      }
    }),
    sort: JSON.stringify([{ createdAt: 'asc', attempt: 'asc' }])
  }
  try {
    const res = await Vue.prototype.$api.searchCallLogs(searchParams)
    if (res?.hits?.length > 0) {
      allLogs = res.hits.map(item => Object.assign(item, { callLog: JSON.parse(item.callLog) }))
      activeLogs = allLogs.filter(itm => !itm.callLog.cancelled)
    }
  } catch (err) {
    console.error(err)
  }
  return { allLogs, activeLogs }
}

export const state = () => ({
  ...defaultState
})

export const getters = {
  currentLogEditDisabled (s) {
    return !s.currentLog.callLogger.id || (s.currentLog.callLogger.id && s.currentLog.callLog.saved)
  }
}

export const mutations = {
  SET_DEFAULT (s) {
    s = Object.assign({}, defaultState)
  },
  SET_TOGGLE (s, display) {
    s.toggle = display
  },
  SET_MINI_DRAWER (s, display) {
    s.miniDrawer = display
  },
  SET_ALERT (s, alert) {
    s.alert = alert
  },
  SET_LOADING (s, loading) {
    s.loading = loading
  },
  SET_LOCK (s, lock) {
    if (lock) {
      s.lock.push(lock)
    } else {
      s.lock.shift()
    }
  },
  CLEAR_LOCK (s) {
    s.lock = []
  },
  SET_CURRENT_LOG (s, { id, payload }) {
    s.currentLog[id] = payload
  },
  UPDATE_CURRENT_LOG (s, { id, payload }) {
    Object.assign(s.currentLog[id], payload)
  },
  CLEAR_CURRENT_LOG (s) {
    s.currentLog = {
      callLogger: { ...defaultCallLogger },
      callLog: { ...defaultCallLog },
      originalCallBackTime: null
    }
  },
  UPDATE_CALL_LOG (s, callLog) {
    s.callLogs[callLog.attempt - 1] = callLog
  },
  REFRESH_CALL_LOGS (s, logs) {
    s.callLogs = logs
  },
  CLEAR_CALL_LOGS (s) {
    s.callLogs = []
  },
  SET_INCOMING_CALL (s, incomingCall) {
    s.incomingCall = incomingCall
  },
  CLEAR_INCOMING_CALL (s) {
    s.incomingCall = {}
  },
  SET_SEARCH (s, { date, qrn }) {
    s.search = { date, qrn }
  },
  SHOW_CALL_LOGS_DIALOG (s, value) {
    s.showCallLogsDialog = value
  },
  CLEAR_SEARCH (s) {
    s.search = {}
  },
  UPDATE_CALL_SESSION (s, data) {
    s.callSession = Object.assign({ ...s.callSession }, data)
  },
  CLEAR_CALL_SESSION (s) {
    s.callSession = {}
  },
  SET_INITIATE_CALL (s, value) {
    s.initiateCall = value
  },
  SET_AGENT (s, { agentId, sessionId }) {
    s.agent = { agentId, sessionId, updatedAt: new Date().toISOString() }
  }
}

export const actions = {
  setDefault ({ commit }) {
    commit('SET_DEFAULT')
  },
  setToggle ({ commit }, display) {
    commit('SET_TOGGLE', display)
  },
  setMiniDrawer ({ commit }, display) {
    commit('SET_MINI_DRAWER', display)
  },
  setAlert ({ commit }, alert) {
    commit('SET_ALERT', alert)
  },
  clearAlert ({ commit }) {
    commit('SET_ALERT', {})
  },
  setLoading ({ commit }, loading) {
    commit('SET_LOADING', loading)
  },
  setLock ({ commit }, lock) {
    commit('SET_LOCK', lock)
  },
  clearLock ({ commit }) {
    commit('CLEAR_LOCK')
  },
  setStatus ({ commit }, { loading, alert, lock }) {
    commit('SET_LOADING', loading)
    commit('SET_ALERT', alert)
    commit('SET_LOCK', lock)
  },
  clearStatus ({ commit }) {
    commit('SET_LOADING', false)
    commit('SET_ALERT', {})
    commit('SET_LOCK', false)
  },
  async startNewCall ({ commit }, callLog = {}) {
    const payload = {
      id: `call-log-NOTES-${uuidv4()}`,
      attempt: 1
    }
    await Vue.prototype.$api.createCallLog({ ...payload, callLog })
    commit('SET_ALERT', {})
    commit('CLEAR_CURRENT_LOG')
    commit('UPDATE_CURRENT_LOG', { id: 'callLogger', payload })
    commit('UPDATE_CURRENT_LOG', { id: 'callLog', payload: callLog })
  },
  async getRecentUnsavedCallLog ({ commit }, email) {
    try {
      const searchParams = {
        from: 0,
        size: 1,
        query: JSON.stringify({
          bool: {
            must: [
              { match: { 'csr.keyword': email } },
              { exists: { field: 'source' } },
              { exists: { field: 'leadId' } }
            ],
            must_not: [
              { term: { 'callLog.saved': true } },
              { term: { 'callLog.cancelled': true } },
              { range: { updatedAt: { lt: this.$dayjs().subtract(10, 'minutes').toISOString() } } }
            ]
          }
        }),
        sort: JSON.stringify({ updatedAt: 'desc' })
      }
      const res = await Vue.prototype.$api.searchCallLogs(searchParams)
      if (res.total) {
        const recentUnsavedCallLog = res.hits[0]
        commit('UPDATE_CURRENT_LOG', { id: 'callLogger', payload: recentUnsavedCallLog })
        commit('UPDATE_CURRENT_LOG', { id: 'callLog', payload: JSON.parse(recentUnsavedCallLog.callLog) })
      }
    } catch (err) {
      console.error(err)
    }
  },
  updateCurrentCallLogger ({ commit }, payload) {
    commit('UPDATE_CURRENT_LOG', { id: 'callLogger', payload })
  },
  updateCurrentCallLog ({ commit }, payload) {
    commit('UPDATE_CURRENT_LOG', { id: 'callLog', payload })
  },
  updateOriginalCallBackTime ({ commit }, cbTime) {
    cbTime = Vue.prototype.$dayjs(cbTime)
    commit('SET_CURRENT_LOG', { id: 'originalCallBackTime', payload: cbTime.isValid() ? cbTime : null })
  },
  clearCurrentLog ({ commit }) {
    commit('CLEAR_CURRENT_LOG')
  },
  async updateCallLog ({ commit, state }) {
    let payload
    try {
      payload = {
        ...state.currentLog.callLogger,
        callLog: state.currentLog.callLog
      }

      // adjust payload to schema requirements
      if (!payload.source) {
        delete payload.source
      }
      if (!payload.quoter) {
        delete payload.quoter
      }

      const updateLogResp = await Vue.prototype.$api.updateCallLog(payload)
      if (updateLogResp?.status && updateLogResp?.log) {
        commit('UPDATE_CALL_LOG', updateLogResp.log)
        return updateLogResp
      }

      commit('UPDATE_CURRENT_LOG', { id: 'callLog', payload: { saved: false } })
    } catch (err) {
      console.error(err)
    }
    return false
  },
  async refreshCurrentCallLog ({ commit, state }) {
    try {
      const payload = {
        ...state.currentLog.callLogger,
        callLog: state.currentLog.callLog
      }

      if (payload.quoter && payload.source && payload.attempt) {
        const existingLogger = await getCallLog(payload)
        if (existingLogger?.callLog?.saved) {
          commit('UPDATE_CURRENT_LOG', { id: 'callLogger', payload: existingLogger })
          commit('UPDATE_CURRENT_LOG', { id: 'callLog', payload: existingLogger.callLog })
          return existingLogger
        }
      }
    } catch (err) {
      console.error(err)
    }
  },
  async refreshCallLogs ({ commit, state }, { leadId, qrn, quoter, source, allowInitiateCall = false }) {
    let response = { status: 1 }
    try {
      let currentLog
      let logs = await getCallLogs({ qrn, quoter, source })

      if (allowInitiateCall && state.initiateCall) {
        commit('SET_INITIATE_CALL', false)
        let callLog = !state.currentLog.callLog.saved ? state.currentLog.callLog : {}
        if (state.incomingCall.event === 'incomingCall') {
          callLog = {
            phone: state.incomingCall.phone,
            callChannel: state.incomingCall.callChannel,
            leadType: state.incomingCall.leadType,
            language: state.incomingCall.language
          }
          commit('CLEAR_INCOMING_CALL')
        }
        let newCallLog
        for (let i = 0; i < 3; i++) {
          const callLogId = `call-log-${source.toUpperCase()}-${qrn}`
          let attempt = 1
          if (logs?.allLogs?.length) {
            const positiveAttempts = logs.allLogs.filter(l => l.attempt > 0).map(l => l.attempt)
            attempt = positiveAttempts.length ? Math.max(...positiveAttempts) + 1 : 1
          }
          newCallLog = await this.$api.createCallLog({
            id: callLogId,
            leadId,
            source: source.toUpperCase(),
            quoter: quoter.toUpperCase(),
            attempt,
            qrn,
            callLog
          })
          if (newCallLog?.status && newCallLog?.log) {
            currentLog = newCallLog.log
            break
          }
          await new Promise(resolve => setTimeout(resolve, 1000))
          await this.$api.refreshCallLogIndex(callLogId, attempt)
          logs = await getCallLogs({ qrn, quoter, source })
        }
        if (!newCallLog) {
          response = { status: 0, error: `Unknown error. Try to insert ${qrn} manually` }
        }
      } else if (logs?.activeLogs?.length && !state.currentLog.callLogger.id) {
        const userLogs = logs.activeLogs.filter(l => (!l.csr || l.csr === this.$auth.email) && this.$dayjs().subtract(30, 'minutes').isBefore(l.updatedAt))
        currentLog = userLogs.length && !userLogs.at(-1).callLog.saved ? userLogs.at(-1) : null
      }
      if (currentLog) {
        commit('UPDATE_CURRENT_LOG', { id: 'callLogger', payload: currentLog })
        commit('UPDATE_CURRENT_LOG', { id: 'callLog', payload: currentLog.callLog })
      }
      commit('REFRESH_CALL_LOGS', logs.allLogs)
    } catch (err) {
      console.error(err)
    }
    return response
  },
  clearCallLogs ({ commit }) {
    commit('CLEAR_CALL_LOGS')
  },
  setIncomingCall ({ commit }, incomingCall) {
    commit('SET_INCOMING_CALL', incomingCall)
  },
  clearIncomingCall ({ commit }) {
    commit('CLEAR_INCOMING_CALL')
  },
  async updateContactId ({ commit }, { phone, contactId }) {
    try {
      const searchParams = {
        from: 0,
        size: 1,
        query: JSON.stringify({
          bool: {
            must: [
              { match: { 'callLog.phone': phone } }
            ],
            must_not: [
              { match: { 'callLog.cancelled': true } }
            ]
          }
        }),
        sort: JSON.stringify({ updatedAt: 'desc' })
      }
      const res = await Vue.prototype.$api.searchCallLogs(searchParams)
      if (res.total) {
        const callLogger = res.hits[0]
        const callLog = JSON.parse(callLogger.callLog)
        if (!callLog.amazonContactId) {
          callLog.amazonContactId = contactId
          callLogger.callLog = callLog
          await Vue.prototype.$api.updateCallLog(callLogger)
        }
      }
    } catch (err) {
      console.error(err)
    }
  },
  setSearch ({ commit }, data) {
    commit('SET_SEARCH', data)
  },
  showCallLogsDialog ({ commit }, value) {
    commit('SHOW_CALL_LOGS_DIALOG', value)
  },
  clearSearch ({ commit }) {
    commit('CLEAR_SEARCH')
  },
  updateCallSession ({ commit }, data) {
    commit('UPDATE_CALL_SESSION', data)
  },
  clearCallSession ({ commit }) {
    commit('CLEAR_CALL_SESSION')
  },
  async createTransferSessionAttributes ({ commit, state }, data) {
    try {
      commit('UPDATE_CALL_SESSION', data)
      const response = await this.$api.createTransferSessionAttributes(data)
      // Broadcast response status to update dialer
      const postMsg = {
        event: 'transferSessionAttributesSubmitted',
        status: response.status,
        quoteRefId: data.quoteRefId
      }
      this.$broadcast.connectChannel.postMessage(postMsg)
    } catch (err) {
      console.error(err)
    }
  },
  setInitiateCall ({ commit }, value) {
    commit('SET_INITIATE_CALL', value)
  },
  async loadAgentDetails ({ commit, state }) {
    const { agentId, sessionId, updatedAt } = state.agent
    if (!agentId || !sessionId || !updatedAt || (new Date() - new Date(updatedAt)) >= (1000 * 60 * 10)) {
      const resp = await this.$api.getAgentDetails()
      commit('SET_AGENT', resp)
    }
  }
}
