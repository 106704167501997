export default {
  computed: {
    links () {
      const data = [
        {
          name: this.$i18n.t('navigation.home'),
          path: '/',
          scope: 'Dashboard'
        },
        {
          name: this.$i18n.t('navigation.domains'),
          path: '/admin/domains',
          scope: 'Domains'
        },
        {
          name: this.$i18n.t('navigation.creditCard'),
          path: '',
          scope: 'CreditCard',
          items: [
            {
              name: this.$i18n.t('creditCard.uploader.promoApplicationLabel'),
              path: '/credit-card/promo-applications',
              scope: 'LRApi::getPromoApplications'
            },
            {
              name: this.$i18n.t('creditCard.uploader.BMOUploadLabel'),
              path: '/credit-card/bmo-uploader',
              scope: 'LRApi::createBMOData'
            },
            {
              name: this.$i18n.t('creditCard.uploader.shareResultUploadLabel'),
              path: '/credit-card/share-result-uploader',
              scope: 'LRApi::createFintelData'
            },
            {
              name: this.$i18n.t('creditCard.uploader.giftCardDisputeLabel'),
              path: '/credit-card/gift-card-dispute',
              scope: 'LRApi::getPromoDisputeApplications'
            },
            {
              name: this.$i18n.t('creditCard.processGiftCardLabel'),
              path: '/credit-card/process-gift-cards',
              scope: 'LRApi::processGiftCards'
            }
          ]
        },
        {
          name: this.$i18n.t('navigation.users'),
          path: '/admin/users',
          scope: 'Users'
        },
        {
          name: this.$i18n.t('navigation.groups'),
          path: '/admin/groups',
          scope: 'Groups'
        },
        {
          name: this.$i18n.t('navigation.permissions'),
          path: '/admin/permissions',
          scope: 'Permissions'
        },
        {
          name: this.$i18n.t('navigation.settings'),
          path: '/admin/settings',
          scope: 'Settings'
        },
        {
          name: this.$i18n.t('navigation.tools'),
          path: '',
          scope: 'Tools',
          items: [
            {
              name: 'Unfulfilled lead upload',
              path: '/admin/lead-upload'
            }
          ]
        },
        {
          name: this.$i18n.t('navigation.controlCenter'),
          path: '',
          scope: 'ControlCentre',
          items: [
            {
              name: 'Outbound Control',
              path: '/admin/control-center/outbound-control'
            },
            {
              name: 'Bubble Priority',
              path: '/admin/control-center/bubble-priority'
            },
            {
              name: 'Next lead Controls',
              path: '/admin/control-center/next-lead'
            },
            {
              name: 'Outbound Real-Time',
              path: '/admin/control-center/outbound-realtime'
            },
            {
              name: 'Close Leads',
              path: '/admin/control-center/close-leads'
            },
            {
              name: 'Exclude Outbound by Phone',
              path: '/admin/control-center/exclude-outbound/phone'
            },
            {
              name: 'Progressive Dialer Controls',
              path: '/admin/control-center/progressive-dialer-control'
            }
          ]
        },
        {
          name: this.$i18n.t('navigation.announcements'),
          path: '/admin/announcements',
          scope: 'Announcements'
        },
        {
          name: this.$i18n.t('navigation.faqs'),
          path: '/admin/faqs',
          scope: 'Faqs'
        },
        {
          name: this.$i18n.t('navigation.library'),
          path: '/admin/library',
          scope: 'Library'
        },
        {
          name: this.$i18n.t('navigation.libraryLinks'),
          path: '/admin/library/links',
          scope: 'LibraryLinks'
        },
        {
          name: this.$i18n.t('navigation.faq'),
          path: '/help',
          scope: 'Faqs'
        }
      ]

      // remove Domains from the list
      if (!this.$config.APP_CONFIG.hasDomains) {
        data.splice(data.findIndex(e => e.scope === 'Domains'), 1)
      }

      data.splice(data.findIndex(e => e.path === '/admin/annoucements'), 1)
      data.splice(data.findIndex(e => e.path === '/admin/faqs'), 1)
      data.splice(data.findIndex(e => e.path === '/admin/library'), 1)
      data.splice(data.findIndex(e => e.path === '/admin/library/links'), 1)
      data.splice(data.findIndex(e => e.path === '/help'), 1)

      return data
    },
    availableLinks () {
      return this.links.reduce((acc, cur) => {
        if ((cur.path && !cur.path.match(/^\/admin\//)) || this.$auth.accessChecks(cur.scope, ['VIEW'])) {
          if (cur.items) {
            let items = []
            // check permissions for submenu if exists
            items = cur.items.reduce((subMenu, subCur) => {
              if ((subCur.scope && this.$auth.accessChecks(subCur.scope, ['READ'])) || !subCur.scope) {
                subMenu.push(subCur)
              }
              return subMenu
            }, [])
            cur.items = items
          }
          acc.push(cur)
        }
        return acc
      }, [])
    }
  }
}
