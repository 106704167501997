import Vue from 'vue'

const uaParser = require('ua-parser-js')
const dtOptions = { dateStyle: 'full', timeStyle: 'short' }
const tzOptions = { timeZone: 'America/Toronto', timeZoneName: 'short' }
const localeStringOptions = { hour: 'numeric', minute: 'numeric', hour12: true }

class FormatService {
  dateTime (d, locale, options) {
    if (!d) { return '' }
    if (!options) { options = dtOptions }
    return new Intl.DateTimeFormat(`${locale}-CA`, options).format(d)
  }

  localeString (d, locale, options) {
    if (!options) { options = localeStringOptions }
    return d.toLocaleString(`${locale}-CA`, options)
  }

  timeZone (d, locale, options) {
    const timezone = new Intl.DateTimeFormat(`${locale}-CA`, { ...tzOptions, ...options }).format(d)
    return timezone.split(',')[1].trim()
  }

  userAgent (uaString) {
    return uaParser(uaString)
  }

  isObject (o) {
    return o === Object(o) && !this.isArray(o) && typeof o !== 'function'
  }

  isArray (a) {
    return Array.isArray(a)
  }

  isUpperCase (s) {
    return /^[A-Z]*$/.test(s)
  }

  toCamel (s) {
    if (!s.match(/_/g)) {
      if (this.isUpperCase(s.charAt(0))) {
        if (this.isUpperCase(s.charAt(1))) { return s.toLowerCase() }
        return s.charAt(0).toLowerCase() + s.slice(1)
      } else {
        return s
      }
    }
    return s.toLowerCase().replace(/([_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('_', '')
    }).replace('_', '')
  }

  keysToCamel (o) {
    if (this.isObject(o)) {
      const n = {}
      Object.keys(o)
        .forEach((k) => {
          n[this.toCamel(k)] = this.keysToCamel(o[k])
        })
      return n
    } else if (this.isArray(o)) {
      return o.map((i) => {
        return this.keysToCamel(i)
      })
    }
    return o
  }

  getDay (idx, short = false) {
    if (idx && idx >= 0 && idx < 7) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
      const daysShort = ['Sun', 'Mon', 'Tues', 'Thurs', 'Fri', 'Sat']
      return short ? daysShort[idx] : days[idx]
    } else {
      return 'Not available'
    }
  }

  getMonth (idx, short = false) {
    if (idx && idx >= 0 && idx < 12) {
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return short ? monthShort[idx] : months[idx]
    } else {
      return 'Not available'
    }
  }

  phone (n) {
    // strip off +1 from the front
    if (n && n.match(/^\+1/)) {
      return n.slice(2)
    }
    return n
  }
}

export default (context, inject) => {
  const formatService = new FormatService()
  context.$format = Vue.prototype.$format = Vue.$format = formatService
  inject('format', formatService)
}
