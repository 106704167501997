var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialogs.create)?_c('base-dialog',{attrs:{"vuetify-props":{
      value: _vm.dialogs.create.show,
      width: _vm.dialogs.create.width || _vm.width,
      closeAriaLabel: _vm.dialogs.create.closeAriaLabel
    }},on:{"close":function($event){return _vm.close('create')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.dialogs.create.title || _vm.$i18n.t('create'))+"\n    ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"pt-2"},[_vm._t("create-update"),_vm._v(" "),_vm._t("create"),_vm._v(" "),(_vm.dialogs.create.error)?_c('p',{staticClass:"error--text text-body-2"},[_vm._v("\n          "+_vm._s(_vm.$i18n.t('errors.general'))+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.confirmAction)?_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","block":"","large":"","loading":_vm.loading,"disabled":_vm.dialogs.create.disabled || _vm.loading},on:{"click":function($event){_vm.$emit('confirm-create-item'); _vm.setConfirmAction(true)}}},[_vm._v("\n            "+_vm._s(_vm.$i18n.t('create'))+"\n          ")])],1):_c('div',[_vm._v("\n          "+_vm._s(_vm.dialogs.create.confirmMessage || 'Are you sure you want to perform this action?')+"\n          "),_c('v-row',{staticClass:"d-flex mt-3 d-inline-block"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){_vm.$emit('create-item'); _vm.setConfirmAction(false)}}},[_vm._v("\n                "+_vm._s(_vm.$i18n.t('yes'))+"\n              ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){return _vm.setConfirmAction(false)}}},[_vm._v("\n                "+_vm._s(_vm.$i18n.t('no'))+"\n              ")])],1)],1)],1)],2)]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),(_vm.dialogs.update)?_c('base-dialog',{attrs:{"vuetify-props":{
      value: _vm.dialogs.update.show,
      width: _vm.dialogs.update.width || _vm.width,
      closeAriaLabel: _vm.dialogs.update.closeAriaLabel
    }},on:{"close":function($event){return _vm.close('update')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.dialogs.update.title || _vm.$i18n.t('update'))+"\n    ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"pt-2"},[_vm._t("create-update"),_vm._v(" "),_vm._t("update"),_vm._v(" "),_vm._t("update-alert"),_vm._v(" "),(_vm.dialogs.update.error)?_c('p',{staticClass:"error--text text-body-2"},[_vm._v("\n          "+_vm._s(_vm.$i18n.t('errors.general'))+"\n        ")]):_vm._e(),_vm._v(" "),(!_vm.confirmAction)?_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","block":"","large":"","loading":_vm.loading,"disabled":_vm.dialogs.update.disabled || _vm.loading},on:{"click":function($event){_vm.$emit('confirm-update-item'); _vm.setConfirmAction(true)}}},[_vm._v("\n            "+_vm._s(_vm.$i18n.t('update'))+"\n          ")])],1):_c('div',[_vm._v("\n          "+_vm._s(_vm.dialogs.update.confirmMessage || 'Are you sure you want to perform this action?')+"\n          "),_c('v-row',{staticClass:"d-flex mt-3 d-inline-block"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){_vm.$emit('update-item'); _vm.setConfirmAction(false)}}},[_vm._v("\n                "+_vm._s(_vm.$i18n.t('yes'))+"\n              ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){return _vm.setConfirmAction(false)}}},[_vm._v("\n                "+_vm._s(_vm.$i18n.t('no'))+"\n              ")])],1)],1)],1)],2)]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),(_vm.dialogs.delete)?_c('base-dialog',{attrs:{"vuetify-props":{
      value: _vm.dialogs.delete.show,
      width: _vm.dialogs.delete.width || _vm.width,
      closeAriaLabel: _vm.dialogs.delete.closeAriaLabel
    }},on:{"close":function($event){return _vm.close('delete')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.dialogs.delete.title || _vm.$i18n.t('delete'))+"\n    ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"pt-2"},[_vm._t("delete"),_vm._v(" "),_vm._t("delete-alert"),_vm._v(" "),(_vm.dialogs.delete.error)?_c('p',{staticClass:"error--text text-body-2"},[_vm._v("\n          "+_vm._s(_vm.$i18n.t('errors.general'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"d-flex mt-3 d-inline-block"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary","loading":_vm.loading,"disabled":_vm.dialogs.delete.disabled || _vm.loading},on:{"click":function($event){return _vm.$emit('delete-item')}}},[_vm._v("\n              "+_vm._s(_vm.$i18n.t('yes'))+"\n            ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){return _vm.close('delete')}}},[_vm._v("\n              "+_vm._s(_vm.$i18n.t('no'))+"\n            ")])],1)],1)],2)]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),(_vm.dialogs.generic)?_c('base-dialog',{attrs:{"vuetify-props":{
      value: _vm.dialogs.generic.show,
      width: _vm.dialogs.generic.width || _vm.width,
      closeAriaLabel: _vm.dialogs.generic.closeAriaLabel
    }},on:{"close":function($event){return _vm.close('generic')}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.dialogs.generic.title || 'Confirm Action')+"\n    ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"pt-2"},[_vm._t("generic"),_vm._v(" "),_c('v-row',{staticClass:"d-flex mt-3 d-inline-block"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary","loading":_vm.loading,"disabled":_vm.dialogs.generic.disabled || _vm.loading},on:{"click":function($event){return _vm.$emit('generic-item', {
                actionType: _vm.dialogs.generic.actionType,
                actionArgs: _vm.dialogs.generic.actionArgs
              })}}},[_vm._v("\n              "+_vm._s(_vm.$i18n.t('yes'))+"\n            ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){return _vm.close('generic')}}},[_vm._v("\n              "+_vm._s(_vm.$i18n.t('no'))+"\n            ")])],1)],1)],2)]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }