//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import outboundMixin from '@/mixins/outbound'
import controlCenterMixin from '@/mixins/controlCenter'

export default {
  mixins: [outboundMixin, controlCenterMixin],
  data () {
    return {
      showOutboundDialog: false,
      hideOutboundComponent: true,
      interval: null,
      outboundCount: null
    }
  },
  async fetch () {
    this.hideOutboundComponent = await this.getHideOutboundComponentValue()
  },
  watch: {
    showOutboundDialog: {
      handler (val) {
        if (val) {
          // Reset filters to default state
          this.$store.dispatch('outbound/setOutboundFilters', [
            'match::lastHandledBy.keyword::unassigned',
            `match::lastHandledBy.keyword::${this.$auth.email}`
          ])
          this.$root.$emit('refresh-outbound-table')
        }
      }
    }
  },
  async mounted () {
    this.$root.$on('open-outbound-dialog', () => this.open())
    this.$root.$on('close-outbound-dialog', () => this.close())
    this.$root.$on('refresh-outbound-count', () => {
      this.setOutboundCount()
    })
    // Get count once on mount and then update after every 5 minutes
    await this.setOutboundCount()
    this.interval = setInterval(async () => {
      await this.setOutboundCount()
    }, 300000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    async setOutboundCount () {
      try {
        if (!this.$permissions.groupCanSearchOutbound()) {
          return
        }
        const res = await this.$api.searchOutbound({
          from: 0,
          size: 10,
          query: this.getOutboundEligibleQuery()
        })
        this.outboundCount = res?.total ? res.total : null
      } catch (err) {
        console.error(err)
      }
    },
    async getHideOutboundComponentValue () {
      try {
        if (this.$auth.groups.some(group => /^(Admin|CSRAdmin)$/.test(group))) {
          return false
        }
        const settings = await this.getSettings('PROGRESSIVE_DIALER_CONTROL')
        if (settings) {
          return JSON.parse(settings.json)?.hideOutboundBubble
        }
      } catch (err) {
        console.error(err)
      }
      return false
    },
    open () {
      this.showOutboundDialog = true
      this.$store.dispatch('callLogger/showCallLogsDialog', false)
    },
    close () {
      this.showOutboundDialog = false
    }
  }
}
