import Vue from 'vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const customParseFormat = require('dayjs/plugin/customParseFormat')
const advancedFormat = require('dayjs/plugin/advancedFormat')

function dayjsService (option, dayjsClass, dayjsFactory) {
  dayjsClass.prototype.getDateOffset = function (value, type = null) {
    if (/^(years|months|days)$/.test(type)) {
      return this.subtract(value, type)
    } else {
      const date = dayjsFactory(value)
      return this.subtract(date.year(), 'years').subtract(date.month(), 'months').subtract(date.date() - 1, 'days')
    }
  }
}

dayjs.extend(customParseFormat)
dayjs.extend(dayjsService)
dayjs.extend(utc)
dayjs.extend(advancedFormat)

export default (context, inject) => {
  context.$dayjs = Vue.prototype.$dayjs = Vue.$dayjs = dayjs
  inject('dayjs', dayjs)
}
