//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiMenu, mdiClose, mdiChevronDown } from '@mdi/js'
import dashboardMenuMixin from '@/mixins/dashboardMenu'

export default {
  mixins: [dashboardMenuMixin],
  data () {
    return {
      mdiChevronDown,
      menuIcon: mdiMenu,
      showNavDrawer: false
    }
  },
  computed: {
    currentLinkIndex () {
      const index = this.links.findIndex(link =>
        !link.isMobileOnly &&
        (
          this.localePath(link.path) === this.$route.path ||
          `${this.localePath(link.path)}/` === this.$route.path ||
          (link.items && (
            link.items.map(i => i.path).includes(this.$route.path) ||
            link.items.map(i => `${this.localePath(i.path)}/`).includes(this.$route.path)
          ))
        )
      )
      // Workaround to highlight dropdown after first selection
      if (this.$refs?.dashboardMenu?.$refs?.items) {
        this.$nextTick(() => this.$refs.dashboardMenu.$refs.items.updateInternalValue(this.$route.path))
      }
      return index
    },
    hideSlider () {
      return this.currentLinkIndex < 0
    }
  },
  mounted () {
    this.$root.$on('open-ambssador-dialer-window', this.openDialer)
    this.$root.$on('nav-drawer-value-change', (value) => {
      this.menuIcon = value ? mdiClose : mdiMenu
    })
  },
  methods: {
    openNavDrawer () {
      this.$root.$emit('open-nav-drawer')
    },
    openDialer () {
      if (!window.ambassadorDialer || window.ambassadorDialer.name !== 'ambassador-dialer') {
        window.ambassadorDialer = window.open('/ambassador-dialer', 'ambassador-dialer', 'width=800,height=500,toolbar=0,scrollbars=yes,resizable=yes,status=0,location=0,menubar=0')
      }
    },
    openBrokerDialer () {
      window.open('/broker-dialer', 'broker-dialer', 'width=800,height=500,toolbar=0,scrollbars=yes,resizable=yes,status=0,location=0,menubar=0')
    }
  }
}
