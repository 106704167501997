export default ({ app, store, $api, $auth }) => {
  app.router.afterEach((to, from) => {
    try {
      $api.subscriptions.onPostEvent.forEach((s) => {
        s.unsubscribe()
      })
      $api.subscriptions.onUpdateRcDialerOutbound.forEach((s) => {
        s.unsubscribe()
      })
    } catch (err) {
      // probably $api.subscriptions.onPostEvent doesn't exist
      console.warn(err)
    }
    if (store.state.auth.isAuthenticated) {
      // global events
      $api.onPostEventSubscribe('global')
      // cognito user events
      $api.onPostEventSubscribe(store.state.auth.user.username)
      // RC auto dialer
      $api.onUpdateRcDialerOutboundSubscribe()
      // lookup code events
      const lookupCodes = $auth.lookupCodes
      if (lookupCodes) {
        lookupCodes.split(' ').forEach(lookupCode =>
          $api.onPostEventSubscribe(lookupCode)
        )
      }
    }
  })
}
