import Vue from 'vue'

class UtilsService {
  isScoop (value) {
    return /(ktx|scoop)/i.test(value)
  }
}

export default (context, inject) => {
  const utilsService = new UtilsService()
  context.$utils = Vue.prototype.$utils = Vue.$utils = utilsService
  inject('utils', utilsService)
}
