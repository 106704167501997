import formatter from '@/mixins/formatter'

export default {
  mixins: [formatter],
  data () {
    return {
      today: this.$dayjs().format('YYYY-MM-DD')
    }
  },
  computed: {
    params () {
      if (this.$route.params.qrn) {
        return {
          qrn: this.$route.params.qrn,
          quoter: this.$route.path.split('/')[2],
          source: this.$route.query.source
        }
      }
      return null
    },
    province () {
      try {
        const lead = this.$store.state.lead.lead
        if (this.params.quoter === 'auto' || this.params.quoter === 'irca') {
          return lead.cq_json.FortusTransaction.Province
        } else if (this.params.quoter === 'home' || this.params.quoter === 'commercialProperty') {
          return lead.cq_json.QuoteInfo.Client.Address.Province
        } 
      } catch (err) {
        console.error(err)
      }
      return null
    },
    defaultLanguage () {
      return this.province === 'QC' ? 'French' : 'English'
    },
    isOutbound () {
      return /(Outbound|Whitelabel|Ratesbot|Sonnet Unfulfilled OB)/.test(this.$store.state.callLogger.currentLog.callLog.callChannel)
    }
  },
  methods: {
    isQrnFormat (qrn) {
      return /^K[A-Z]\d{6}-[A-Z0-9]{5}(-LR)?$/.test(qrn)
    },
    setAlert (type, id, message = null) {
      this.$store.dispatch('callLogger/setAlert', { type, id, message })
    },
    formRules (ids = []) {
      const rules = {
        required: v => (v != null && v !== '') || 'fillRequiredFields',
        onlyDigits: v => (!v || /^\d+$/.test(v)) || 'onlyDigits',
        phoneFormat: v => (!v || v?.length === 10) || 'phoneFormat',
        qrn: v => (!v || /^K[A-Z]\d{6}-[A-Z0-9]{5}(-LR)?$/.test(v)) || 'wrongQrnFormat',
        onlyFutureTime: v => (v && this.$dayjs().isBefore(`${this.$store.state.callLogger.currentLog.callLog.callbackDate} ${v}`)) || 'onlyFutureTime'
      }
      return ids.map(id => rules[id])
    },
    async updateCallLog (saved = false) {
      try {
        this.$store.dispatch('callLogger/updateCurrentCallLog', { saved })
        return await this.$store.dispatch('callLogger/updateCallLog')
      } catch (err) {
        console.error(err)
      }
      this.setAlert('error', 'autoSaveError')
      return false
    },
    async getOutboundDetails ({ source, qrn }) {
      if (!source || !qrn) {
        return null
      }
      try {
        const searchParams = {
          from: 0,
          size: 1,
          query: JSON.stringify({
            bool: {
              should: [
                { match: { source: source.toUpperCase() } },
                { match: { 'qrn.keyword': qrn } }
              ]
            }
          })
        }
        const response = await this.$api.searchOutbound(searchParams)
        if (response?.hits?.length > 0) {
          return response.hits[0]
        }
      } catch (err) {
        console.error(err)
      }
      return null
    },
    async setCallbackDateTime () {
      const currentLog = this.$store.state.callLogger.currentLog
      try {
        if (!currentLog.callLog.callback ||
          (currentLog.callLog.callback === 'Default' && currentLog.callLogger.attempt >= 3 && currentLog.callLog.callType === 'Not-connected')
        ) {
          return false
        }
        let callbackTime
        if (currentLog.callLog.callback === 'Default') {
          const callbackDateTime = await this.getDefaultCallbackTime()
          this.$store.dispatch('callLogger/updateCurrentCallLog', {
            callbackDate: callbackDateTime.format('YYYY-MM-DD'),
            callbackTime: callbackDateTime.format('HH:mm')
          })
          callbackTime = callbackDateTime.toISOString()
        } else if (currentLog.callLog.callback === 'Custom') {
          callbackTime = this.$dayjs(`${currentLog.callLog.callbackDate} ${currentLog.callLog.callbackTime}`).toISOString()
        } else {
          this.$store.dispatch('callLogger/updateCurrentCallLog', {
            callbackDate: this.$dayjs().format('YYYY-MM-DD'),
            callbackTime: this.$dayjs().add(...currentLog.callLog.callback.split(' ')).format('HH:mm')
          })
          callbackTime = this.$dayjs().add(...currentLog.callLog.callback.split(' ')).toISOString()
        }
        return callbackTime
      } catch (err) {
        console.error(err)
        this.$api.createJsonLog({
          scope: 'wc::callLogger::mixins::setCallbackDateTime',
          event: 'error',
          source: currentLog.callLogger.source || 'LR',
          log: { quote_ref_id: currentLog.callLogger.qrn, error: { stack: err.stack, message: err.message } }
        })
      }
      return null
    },
    async getDefaultCallbackTime () {
      const currentLog = this.$store.state.callLogger.currentLog
      const outboundDetails = await this.getOutboundDetails(currentLog.callLogger)
      const isScoop = this.$utils.isScoop(outboundDetails?.leadType)
      const day = this.$dayjs().day()

      // Get scheduled callback time for the current call
      const cbTime = currentLog.originalCallBackTime ? currentLog.originalCallBackTime : this.$dayjs()
      const cbTimeInt = parseInt(cbTime.format('HHmm'))
      const cbHour = cbTime.hour()
      const cbMin = cbTime.minute()

      // This is the first time we are calling customer, deal with future attempt #2 default time
      if (currentLog.callLogger.attempt === 1) {
        if (day === 5) {
          // If Friday and it's Scoop, then next attempt always on Monday at 10:00am
          if (isScoop) {
            return this.$dayjs().add(3, 'day').hour(10).minute(0).second(0)
          // If Friday and time between 08:00 and 9:59, then next day at 10
          } else if (cbTimeInt >= 800 && cbTimeInt < 1000) {
            return this.$dayjs().add(1, 'day').hour(10).minute(0).second(0)
          // If Friday and between 10 and 18, then next day at A1 cbTime
          } else if (cbTimeInt >= 1000 && cbTimeInt <= 1800) {
            return this.$dayjs().add(1, 'day').hour(cbHour).minute(cbMin).second(0)
          // If Friday and between 18 and 20 (also as fallback), then next attempt next day at 14:00pm
          } else {
            return this.$dayjs().add(1, 'day').hour(14).minute(0).second(0)
          }
        } else if (day === 6) {
          // If Saturday and between 10 and 16, then next attempt on Monday at A1 cb time
          if (cbTimeInt >= 1000 && cbTimeInt <= 1600) {
            return this.$dayjs().add(2, 'day').hour(cbHour).minute(cbMin).second(0)
          // If Saturday and between 8 and 10 (also as fallback), then next attempt on Monday at 10:00am
          } else {
            return this.$dayjs().add(2, 'day').hour(10).minute(0).second(0)
          }
        // A1 callback time between 08:00 and 9:59, then next day at 10
        } else if (cbTimeInt >= 800 && cbTimeInt < 1000) {
          return this.$dayjs().add(1, 'day').hour(10).minute(0).second(0)
        // A1 between 10 and 18 then next day at A1 time
        } else if (cbTimeInt >= 1000 && cbTimeInt < 1800) {
          return this.$dayjs().add(1, 'day').hour(cbHour).minute(cbMin).second(0)
        // A1 between 18 and 20 then next day at 14
        } else {
          return this.$dayjs().add(1, 'day').hour(14).minute(0).second(0)
        }
      // This is our 2nd call, deal with default callback time for attempt #3
      } else if (currentLog.callLogger.attempt === 2) {
        if (day === 5) {
          // if Friday and Scoop - always return Mon at 11
          if (isScoop) {
            return this.$dayjs().add(3, 'day').hour(11).minute(0).second(0)
          // If Friday and time between 08:00 and 9:59, then next day at 11
          } else if (cbTimeInt >= 800 && cbTimeInt < 1000) {
            return this.$dayjs().add(1, 'day').hour(11).minute(0).second(0)
          // If Friday and time between 10:00 and 17:59, then next day at A2 time
          } else if (cbTimeInt >= 1000 && cbTimeInt <= 1800) {
            return this.$dayjs().add(1, 'day').hour(cbHour).minute(cbMin).second(0)
          } else {
            // If Friday and between 18 and 20 (also as fallback), then next attempt on Monday at 16:00pm
            return this.$dayjs().add(3, 'day').hour(16).minute(0).second(0)
          }
        } else if (day === 6) {
          // If Saturday and between 10 and 16, then next attempt on Monday at A1 cbTime
          if (cbTimeInt >= 1000 && cbTimeInt <= 1600) {
            return this.$dayjs().add(2, 'day').hour(cbHour).minute(cbMin).second(0)
          // If Saturday and between 8 and 10 (also as fallback), then next attempt on Monday at 11:00am
          } else {
            return this.$dayjs().add(2, 'day').hour(11).minute(0).second(0)
          }
        // A2 between 8 and 10 return next day at 11
        } else if (cbTimeInt >= 800 && cbTimeInt < 1000) {
          return this.$dayjs().add(1, 'day').hour(11).minute(0).second(0)
        // A2 between 10 and 18 then return next day at A2 time
        } else if (cbTimeInt >= 1000 && cbTimeInt < 1800) {
          return this.$dayjs().add(1, 'day').hour(cbHour).minute(cbMin).second(0)
        // A2 between 18 and 20 then A3 next day at 16
        } else {
          return this.$dayjs().add(1, 'day').hour(16).minute(0).second(0)
        }
      }
      // Fallback, shouldn't get here... Return next day at 14:00
      return this.$dayjs().add(1, 'day').hour(14).minute(0).second(0)
    }
  }
}
