export const state = () => ({
  open: false
})

export const mutations = {
  SET_OPEN (s, open) {
    s.open = open
  }
}

export const actions = {
  setOpen ({ commit }, open) {
    commit('SET_OPEN', open)
  }
}
