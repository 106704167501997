
const defaultState = {
  creditCard: {}
}

export const state = () => ({
  ...defaultState
})

export const mutations = {
  CREATE_BMO_DATA (s, csvData) {
    s.creditCard = csvData
  },
  CREATE_FINTEL_DATA (s, fintelData) {
    s.creditCard = fintelData
  },
  GET_PROMO_APP (s) {
  },
  GET_PROMO_FILTERS (s) {
  },
  GET_DISPUTE_APP (s) {
  }
}

export const actions = {
  createBMOData ({ commit }, csvData) {
    commit('CREATE_BMO_DATA', csvData)
  },
  createFintelData ({ commit }, fintelData) {
    commit('CREATE_FINTEL_DATA', fintelData)
  },
  getPromoFilters ({ commit }) {
    commit('GET_PROMO_FILTERS')
  },
  getPromoApplications ({ commit }) {
    commit('GET_PROMO_APP')
  },
  getPromoDisputeApplications ({ commit }) {
    commit('GET_DISPUTE_APP')
  }
}
