//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiClose } from '@mdi/js'

export default {
  props: {
    announcements: {
      type: Array,
      default: () => []
    },
    height: {
      type: Number,
      default: 76 // mobile default
    }
  },
  data () {
    return { mdiClose }
  },
  methods: {
    closeAnnouncement (announcementId) {
      this.$store.dispatch('announcements/deleteAnnouncement', announcementId)
    }
  }
}
