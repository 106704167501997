//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import helperFunctions from '@/mixins/helperFunctions'

export default {
  name: 'LayoutsDefault',
  mixins: [helperFunctions],
  data () {
    return {
      menu: null,
      fixed: false,
      title: 'Web Central',
      notification: {
        show: false,
        text: ''
      }
    }
  },
  head () {
    return {
      title: `${this.title} - ${this.$route.path}`
    }
  },
  computed: {
    announcements () {
      return this.$store.state.announcements.items.filter(e => e.enabled)
    },
    announcementHeight () {
      return this.$vuetify.breakpoint.smAndDown ? 76 : 54
    },
    appBarHeight () {
      return (this.$vuetify.breakpoint.mdAndUp ? 96 : 64) + (this.announcements.length * this.announcementHeight)
    },
    menuDrawerStyle () {
      return {
        'margin-top': `${this.appBarHeight}px`,
        height: `calc(100% - ${this.appBarHeight}px)`
      }
    }
  },
  watch: {
    '$vuetify.theme.dark': {
      handler () {
        this.$store.dispatch('settings/setDarkTheme', this.$vuetify.theme.dark)
      }
    }
  },
  mounted () {
    if (this.$store.state.settings) {
      this.$vuetify.theme.dark = this.$store.state.settings.dark
    }
  },
  methods: {
    reloadPage () {
      location.reload()
    }
  }
}
