export default {
  methods: {
    currencyFormatter (number, options = {}) {
      return new Intl.NumberFormat(
        this.$i18n.locales.find(l => l.code === this.$i18n.locale).iso,
        Object.assign(
          {
            style: 'currency',
            currency: 'CAD',
            currencyDisplay: 'narrowSymbol'
          },
          options
        )
      ).format(number)
    },
    numberFormatter (number, options = {}) {
      return new Intl.NumberFormat(
        this.$i18n.locales.find(l => l.code === this.$i18n.locale).iso,
        Object.assign(
          { style: 'decimal' },
          options
        )
      ).format(number)
    },
    capitalizeFirstLetter (str) {
      if (typeof str === 'string') {
        return str.charAt(0).toUpperCase() + str.slice(1)
      }
      return str
    }
  }
}
