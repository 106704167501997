//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    dialogs: {
      type: Object,
      default () {
        return {
          /**
           * i.e.
           * create: {
           *   show: false
           * }
           */
        }
      }
    },
    width: {
      type: Number,
      default: 500
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confirmAction: false
    }
  },
  methods: {
    close (type) {
      this.$emit('on-close', type)
      this.setConfirmAction(false)
      this.dialogs[type].show = false
    },
    setConfirmAction (value) {
      this.confirmAction = value
    }
  }
}
