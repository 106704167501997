export const state = () => ({
  outboundFilters: []
})

export const mutations = {
  SET_OUTBOUND_FILTERS (s, outboundFiltersArray) {
    s.outboundFilters = [...outboundFiltersArray]
  }
}

export const actions = {
  setOutboundFilters ({ commit }, outboundFiltersArray) {
    commit('SET_OUTBOUND_FILTERS', outboundFiltersArray)
  }
}
