//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data () {
    return {
      headers: ['Page', 'Url Structure', 'Example url/link'],
      siteMapData: {
        indexPages: {
          heading: 'Index Pages',
          pages: [
            {
              id: 'index',
              url: '/',
              exampleUrl: '/',
              page: 'Quote / Transfers Search Page'
            },
            {
              id: 'login',
              url: '/login',
              exampleUrl: '/login',
              page: 'Login Page'
            },
            {
              id: 'forgot-password',
              url: '/forgot-password',
              exampleUrl: '/forgot-password',
              page: 'Forgot-password Page'
            },
            {
              id: 'confirm-reset-password',
              url: '/confirm-reset-password',
              exampleUrl: '/confirm-reset-password',
              page: 'Confirm-reset-password Page'
            },
            {
              id: 'sso',
              url: '/sso',
              exampleUrl: '/sso',
              page: 'Sso Page'
            },
            {
              id: 'profile',
              url: '/profile',
              exampleUrl: '/profile',
              page: 'Profile Page'
            },
            {
              id: 'help',
              url: '/help',
              exampleUrl: '/help',
              page: 'Help Page - Do we need it?'
            },
            {
              id: 'register',
              url: '/register',
              exampleUrl: '/register',
              page: 'Register Page - for self register - Do we need it?'
            }
          ]
        },
        adminPages: {
          heading: 'Admin Pages',
          pages: [
            {
              id: 'users',
              url: '/admin/users',
              exampleUrl: '/admin/users',
              page: 'Users Page'
            },
            {
              id: 'permissions',
              url: '/admin/permissions',
              exampleUrl: '/admin/permissions',
              page: 'Permissions Page'
            },
            {
              id: 'groups',
              url: '/admin/groups',
              exampleUrl: '/admin/groups',
              page: 'Groups Page'
            },
            {
              id: 'domains',
              url: '/admin/domains',
              exampleUrl: '/admin/domains',
              page: 'Domains Page'
            },
            {
              id: 'faqs',
              url: '/admin/faqs',
              exampleUrl: '/admin/faqs',
              page: 'Faqs Page - Do we need it?'
            },
            {
              id: 'announcements',
              url: '/admin/announcements',
              exampleUrl: '/admin/announcements',
              page: 'Announcements Page - Do we need it?'
            }
          ]
        },
        quotePages: {
          heading: 'Quote Search and Details',
          pages: [
            {
              id: 'quoteSearchResults',
              url: '/quote/search',
              exampleUrl: '/quote/search',
              page: 'Quotes search results page'
            },
            {
              id: 'auto',
              url: '/quote/_autoType/_id',
              exampleUrl: '/quote/auto/12345',
              page: 'View auto quote'
            },
            {
              id: 'commercial-auto',
              url: '/quote/_autoType/_id',
              exampleUrl: '/quote/commercial-auto/12345',
              page: 'View commercial auto quote'
            },
            {
              id: 'irca',
              url: '/quote/_autoType/_id',
              exampleUrl: '/quote/irca/12345',
              page: 'View IRCA quote'
            },
            {
              id: 'moto',
              url: '/quote/_autoType/_id',
              exampleUrl: '/quote/moto/12345',
              page: 'View motorcycle quote'
            },
            {
              id: 'prop',
              url: '/quote/home/_id',
              exampleUrl: '/quote/home/12345',
              page: 'View prop quote'
            },
            {
              id: 'commercial-property',
              url: '/quote/home/_id',
              exampleUrl: '/quote/home/12345',
              page: 'View commercial property quote'
            },
            {
              id: 'credit',
              url: '/quote/credit/_id',
              exampleUrl: '/quote/credit/12345',
              page: 'View credit application'
            },
            {
              id: 'mortgage',
              url: '/quote/mortgage/_id',
              exampleUrl: '/quote/mortgage/12345',
              page: 'View mortgage application'
            },
            {
              id: 'life',
              url: '/quote/life/_id',
              exampleUrl: '/quote/life/12345',
              page: 'View life quote'
            },
            {
              id: 'travel',
              url: '/quote/travel/_id',
              exampleUrl: '/quote/travel/12345',
              page: 'View travel quote'
            }
          ]
        },
        transferPages: {
          heading: 'Transfer Search and Transfer Leads',
          pages: [
            {
              id: 'transferResults',
              url: '/transfer/search',
              exampleUrl: '/transfer/search',
              page: 'Transfer records search results'
            },
            {
              id: 'transferAuto',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/auto/12345/123',
              page: 'Transfer Auto Quote'
            },
            {
              id: 'transferCommercialAuto',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/commercial-auto/12345/123',
              page: 'Transfer Commercial Auto Quote'
            },
            {
              id: 'transferMoto',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/moto/12345/123',
              page: 'Transfer Motorcycle Quote'
            },
            {
              id: 'transferProp',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/prop/12345/123',
              page: 'Transfer Property Quote'
            },
            {
              id: 'transferCommercial',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/prop/12345/123',
              page: 'Transfer Commercial Property Quote'
            },
            {
              id: 'transferCredit',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/credit/12345/123',
              page: 'Transfer Credit Quote'
            },
            {
              id: 'transferMortgage',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/mortgage/12345/123',
              page: 'Transfer Mortgage Quote'
            },
            {
              id: 'transferTravel',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/travel/12345/123',
              page: 'Transfer Travel Quote'
            },
            {
              id: 'transferLife',
              url: '/transfer/_quoter/_id/_quoteId',
              exampleUrl: '/transfer/life/12345/123',
              page: 'Transfer Life Quote'
            }
          ]
        },
        controlCenterPages: {
          heading: 'Control Center',
          pages: [
            {
              id: 'outboundControl',
              url: '/admin/control-center/outbound-control',
              exampleUrl: '/admin/control-center/outbound-control',
              page: 'Outbound Control'
            },
            {
              id: 'bubble',
              url: '/admin/control-center/bubble-priority',
              exampleUrl: '/admin/control-center/bubble-priority',
              page: 'Bubble Leads Priority Control'
            },
            {
              id: 'nextLead',
              url: '/admin/control-center/next-lead',
              exampleUrl: '/admin/control-center/next-lead',
              page: 'Next lead Controls'
            },
            {
              id: 'realTime',
              url: '/admin/control-center/outbound-realtime',
              exampleUrl: '/admin/control-center/outbound-realtime',
              page: 'Quote/Lead Count'
            },
            {
              id: 'closeLeads',
              url: '/admin/control-center/close-leads',
              exampleUrl: '/admin/control-center/close-leads',
              page: 'Close Leads'
            },
            {
              id: 'excludeOutboundPhone',
              url: '/admin/control-center/exclude-outbound/_type',
              exampleUrl: '/admin/control-center/exclude-outbound/phone',
              page: 'Exclude Outbound by Phone'
            },
            {
              id: 'excludeOutboundEmail',
              url: '/admin/control-center/exclude-outbound/_type',
              exampleUrl: '/admin/control-center/exclude-outbound/email',
              page: 'Exclude Outbound by Email'
            }
          ]
        }
      }
    }
  },
  methods: {
    closeDialog () {
      this.$root.$emit('close-dialog')
    }
  }
}
