//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AppsyncPlayground from 'appsync-playground'
import { mdiChevronUp } from '@mdi/js'

export default {
  name: 'StoreDebug',
  components: {
    AppsyncPlayground
  },
  data () {
    return {
      panel: [],
      dialogPersistent: false,
      dialog: false,
      tab: 'data_inspector',
      fab: false,
      icons: {
        mdiChevronUp
      },
      queries: {},
      mutations: {}
    }
  },
  async beforeMount () {
    this.$root.$on('close-dialog', () => {
      this.dialog = false
    })
    try {
      // If files are missing or import fails, assign empty objects
      this.queries = await import('@/src/graphql/queries.js')
      this.mutations = await import('@/src/graphql/mutations.js')
    } catch (err) {
      this.queries = {}
      this.mutations = {}
    }
  },
  methods: {
    toTop () {
      document.getElementsByClassName('v-dialog--active')[0].scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}
