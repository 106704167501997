//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dataTableMixin from '@/mixins/dataTable'
import helperFunctions from '@/mixins/helperFunctions'
import outboundMixin from '@/mixins/outbound'
import notificationMixin from '@/mixins/notification'

export default {
  mixins: [dataTableMixin, helperFunctions, outboundMixin, notificationMixin],
  props: {
    preview: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filtersToggle: false,
      luckyLoading: false,
      controlSettings: {},
      footerProps: {
        itemsPerPageOptions: [100, 500, 1000],
        options: {
          page: 1,
          itemsPerPage: 100
        }
      }
    }
  },
  computed: {
    headers () {
      const headers = [
        { text: this.$i18n.t('outbound.headers.brand'), value: 'source', sortable: false },
        { text: this.$i18n.t('outbound.headers.quoteName'), value: 'actions', sortable: false },
        { text: this.$i18n.t('outbound.headers.name'), value: 'firstName', sortable: false },
        { text: this.$i18n.t('outbound.headers.callbackTime'), value: 'callbackTime', sortable: false },
        { text: this.$i18n.t('outbound.headers.province'), value: 'province', sortable: false },
        { text: this.$i18n.t('outbound.headers.leadType'), value: 'leadType', sortable: false },
        { text: this.$i18n.t('outbound.headers.attempts'), value: 'attempts', sortable: false },
        { text: this.$i18n.t('outbound.headers.handledBy'), value: 'lastHandledBy', sortable: false }
      ]

      return headers
    },
    selectedOutboundFilters () {
      return this.$store.state.outbound.outboundFilters
    }
  },
  watch: {
    selectedOutboundFilters () {
      this.refreshData()
    }
  },
  mounted () {
    this.$root.$on('refresh-outbound-table', (payload) => {
      this.refreshData(payload)
    })
  },
  methods: {
    async getNextLeadControlSettings () {
      try {
        const resp = await this.$api.searchControlCentreConfig({
          from: 0,
          size: 1,
          query: JSON.stringify({ bool: { must: [{ match: { scope: 'NEXT_LEAD' } }] } })
        })
        const controlJson = JSON.parse(resp.hits[0].json)
        return controlJson.settings.reduce((acc, curr) => {
          acc[curr.key] = curr.val
          return acc
        }, {})
      } catch (err) {
        console.error(err)
      }
      return {}
    },
    async refreshData (payload) {
      this.loading = true
      this.items = []
      try {
        if (this.$permissions.groupCanReadQuotes()) {
          this.controlSettings = await this.getNextLeadControlSettings()
          const searchParams = {
            from: (this.footerProps.options.page - 1) * this.footerProps.options.itemsPerPage,
            size: this.footerProps.options.itemsPerPage
          }
          if (this.footerProps.options.sortBy.length) {
            searchParams.sort = JSON.stringify(this.footerProps.options.sortBy.reduce((acc, cur, i) => {
              const sortBy = {}
              sortBy[(cur.match(/^(createdAt)$/)) ? cur : `${cur}.keyword`] = (this.footerProps.options.sortDesc[i]) ? 'desc' : 'asc'
              acc.push(sortBy)
              return acc
            }, []))
          } else {
            const bubblePrioritySort = await this.$api.getBubblePrioritySort(payload?.prioritySettings)
            if (bubblePrioritySort.length) {
              searchParams.sort = JSON.stringify(bubblePrioritySort)
            }
          }
          searchParams.query = this.searchQuery()
          const response = await this.$api.searchOutbound(searchParams)
          if (response?.hits?.length > 0) {
            this.items = response.hits
            this.serverItemsLength = response.total
            this.refreshDate = new Date()
          }
        }
      } catch (err) {
        console.error(err)
      }
      this.loading = false
    },
    searchQuery (type = '') {
      let filters
      if (/^(un)?allocated$/.test(type)) {
        filters = [{
          bool: {
            should: [
              { match: { 'lastHandledBy.keyword': type === 'allocated' ? this.$auth.email : 'unassigned' } }
            ]
          }
        }]
      } else if (this.search !== '') {
        filters = [{
          bool: {
            should: [
              { wildcard: { 'source.keyword': `*${this.search.toUpperCase()}*` } },
              { wildcard: { 'qrn.keyword': `*${this.search.toUpperCase()}*` } }
            ]
          }
        }]
      } else if (this.selectedOutboundFilters.length > 0) {
        filters = []
        const formattedQueryPerFilter = this.selectedOutboundFilters.reduce((acc, cur) => {
          const filterParams = cur.split('::')
          if (!acc[filterParams[1]]) {
            acc[filterParams[1]] = []
          }
          acc[filterParams[1]].push({ [filterParams[0]]: { [filterParams[1]]: filterParams[2] } })
          return acc
        }, {})
        Object.values(formattedQueryPerFilter).forEach(query => filters.push({ bool: { should: query } }))
      }
      return this.getOutboundEligibleQuery(filters)
    },
    async selectItemHandler (item) {
      try {
        if (this.$permissions.groupCanReadOutboundQuotes()) {
          const outboundLead = await this.$api.removeLeadFromOutbound({ qrn: item.qrn, source: item.source, assignment: true })
          if (outboundLead?.status === 1) {
            this.selectItem(item)
          } else {
            this.refreshData()
            this.setNotification('This lead is not available anymore!', 5000)
          }
        }
      } catch (err) {
        console.error(err)
      }
    },
    async selectItem (item) {
      if (this.$permissions.groupCanViewQuotes()) {
        const destinationUrl = `/quote/${item.quoter.toLowerCase()}/${item.qrn}`
        this.$store.dispatch('callLogger/setStatus', {
          loading: true,
          alert: { type: 'info', id: 'loading' },
          lock: true
        })
        this.$root.$emit('close-outbound-dialog')
        this.$store.dispatch('callLogger/setMiniDrawer', false)
        await this.$store.dispatch('callLogger/clearCurrentLog')
        await this.$store.dispatch('callLogger/updateOriginalCallBackTime', item.callbackTime)
        await this.$store.dispatch('callLogger/updateCurrentCallLog', {
          callChannel: this.getCallChannelByLeadType(item),
          leadType: item.leadType
        })
        this.$store.dispatch('callLogger/setInitiateCall', true)
        if (this.$route.path.includes(destinationUrl)) {
          this.$router.push({ path: destinationUrl, query: { source: item.source } })
          const refreshCallLogsResp = await this.$store.dispatch('callLogger/refreshCallLogs', {
            ...item,
            allowInitiateCall: true
          })
          this.$root.$emit('refresh-outbound-controls-button')
          this.$store.dispatch('callLogger/clearLock')
          if (refreshCallLogsResp.status === 1) {
            this.$root.$emit('refresh-call-logger')
          } else {
            this.$store.dispatch('callLogger/clearCurrentLog')
            this.$store.dispatch('callLogger/setAlert', { type: 'error', message: refreshCallLogsResp.error })
            this.$store.dispatch('callLogger/setLoading', false)
          }
        } else {
          this.$router.push(this.localePath(`${destinationUrl}?source=${item.source}`))
        }
      }
    },
    outboundRowColorMapper (item) {
      const colorMap = {
        IH: 'blue lighten-4',
        LR: 'teal lighten-4',
        RATES: 'red lighten-4'
      }

      return colorMap[item.source] ? colorMap[item.source] : ''
    },
    async imFeelingLuckyHandler () {
      this.luckyLoading = true
      let message = ''
      try {
        const data = await Promise.all([
          this.getNextLeadControlSettings(),
          this.$api.searchOutbound({ from: 0, size: 1, query: this.searchQuery('allocated') }),
          this.$api.searchOutbound({ from: 0, size: 1, query: this.searchQuery('unallocated') })
        ])

        const totalCSRs = 20 // For now default it to 20 (we need to simplify it or setup api call to RC)
        const now = this.$dayjs()

        // Update next lead control settings
        this.controlSettings = data[0]

        // Get num of allocated and unallocated leads.
        const numAllocatedLeads = data[1].total
        const numUnallocatedLeads = data[2].total

        // Get closing hours for today
        const kanetixHours = this.getKanetixHours(now.day())
        let closeDateTime = null
        if (kanetixHours[1]) {
          const [hours, minutes] = kanetixHours[1].split(':')
          closeDateTime = now.hour(hours).minute(minutes).second(0)
        }

        // Figure out # number of minutes it will take to finish all of them by all csr working in parallel
        const minsToFinishUnallocated = this.controlSettings.averageCallTime * numUnallocatedLeads / totalCSRs
        const unallocatedFinishDateTime = now.add(minsToFinishUnallocated, 'minute')

        let outboundLead = null
        let category = null

        // Define on which leads work first
        if (!numAllocatedLeads && !numUnallocatedLeads) {
          message = 'There are no leads in the bubble'
          this.refreshData()
          this.setNotification(this.$i18n.t('outbound.errors.bubbleIsEmpty'), 5000)
        } else {
          if (closeDateTime && closeDateTime.isBefore(unallocatedFinishDateTime)) {
            message = 'Too many unallocated leads in the bubble work on those first'
            category = 'unallocated'
          } else if (numUnallocatedLeads > this.controlSettings.maxUnallocatedThreshold) {
            message = 'Unallocated leads above threshold, work on unallocated'
            category = 'unallocated'
          } else if (numAllocatedLeads > this.controlSettings.maxAllocatedThreshold) {
            message = 'Allocated leads above threshold, work on allocated'
            category = 'allocated'
          } else {
            // Finally, catch-all (handle unallocated first)
            category = numUnallocatedLeads > 0 ? 'unallocated' : 'allocated'
            message = `No criteria has been met, work on ${category} (numUnallocatedLeads: ${numUnallocatedLeads})`
          }

          this.$store.dispatch('lead/clearOutbound')
          outboundLead = await this.$api.imFeelingLucky(this.searchQuery(category))
          if (outboundLead?.status && outboundLead?.data) {
            await this.selectItem(outboundLead.data)
          } else {
            this.refreshData()
            this.setNotification(outboundLead?.message || this.$i18n.t('outbound.errors.failed'), 5000)
          }
        }
      } catch (err) {
        console.error(err, message)
        this.setNotification(this.$i18n.t('outbound.errors.failed'), 5000)
      }
      this.luckyLoading = false
    }
  }
}
