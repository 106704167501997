import Vue from 'vue'
import { API, Storage } from 'aws-amplify'
import * as queries from '@/src/graphql/queries'
import * as mutations from '@/src/graphql/mutations'
import * as subscriptions from '@/src/graphql/subscriptions'

class ApiService {
  constructor (ctx) {
    this.ctx = ctx
    this.subscriptions = {
      onPostEvent: [],
      onUpdateRcDialerOutbound: []
    }
  }

  _getFilteredData (data, re) {
    return Object.keys(data).reduce((acc, cur) => {
      if (cur.match(re)) {
        acc[cur] = data[cur]
      }
      return acc
    }, {})
  }

  fakeApiCall (delay) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, delay || 1000)
    })
  }

  async getAvailableGroups () {
    try {
      const groups = await API.graphql({
        query: queries.getAvailableGroups
      })
      return {
        items: groups.data.getAvailableGroups
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchGroups ({ from, size, query, sort }) {
    try {
      const groups = await API.graphql({
        query: queries.searchGroups,
        variables: {
          from, size, query, sort
        }
      })
      return groups.data.searchGroups
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getUsers () {
    try {
      const users = await API.graphql({
        query: queries.getUsers
      })
      return {
        items: users.data.getUsers
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchUsers ({ from, size, query, sort }) {
    try {
      const users = await API.graphql({
        query: queries.searchUsers,
        variables: {
          from, size, query, sort
        }
      })
      return users.data.searchUsers
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createUser (data) {
    try {
      const fields = ['email', 'domains', 'custom__domains', 'phone']
      if (this.ctx.$auth.accessChecks('Users::groups', ['CREATE'])) {
        fields.push('groups')
      }
      return await API.graphql({
        query: mutations.createUser,
        variables: this._getFilteredData(data, new RegExp(`^(${fields.join('|')})$`))
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateUser (data) {
    try {
      const fields = ['username', 'email', 'domains', 'custom__domains', 'phone']
      if (this.ctx.$auth.accessChecks('Users::groups', ['UPDATE'])) {
        fields.push('groups')
      }
      return await API.graphql({
        query: mutations.updateUser,
        variables: this._getFilteredData(data, new RegExp(`^(${fields.join('|')})$`))
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteUser (username) {
    try {
      return await API.graphql({
        query: mutations.deleteUser,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async disableUser (username) {
    try {
      return await API.graphql({
        query: mutations.disableUser,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async enableUser (username) {
    try {
      return await API.graphql({
        query: mutations.enableUser,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async resetUserPassword (username) {
    try {
      return await API.graphql({
        query: mutations.resetUserPassword,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async resendTempPassword (username) {
    try {
      return await API.graphql({
        query: mutations.resendTempPassword,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getGroupPermissions () {
    try {
      const groupPermissions = await API.graphql({
        query: queries.getGroupPermissions
      })
      return {
        items: groupPermissions.data.getGroupPermissions
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchGroupPermissions ({ from, size, query, sort }) {
    try {
      const permissions = await API.graphql({
        query: queries.searchGroupPermissions,
        variables: {
          from, size, query, sort
        }
      })
      return permissions.data.searchGroupPermissions
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createGroupPermission (data) {
    try {
      return await API.graphql({
        query: mutations.createGroupPermission,
        variables: this._getFilteredData(data, /^(group|scope|permissions)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateGroupPermission (data) {
    try {
      return await API.graphql({
        query: mutations.updateGroupPermission,
        variables: this._getFilteredData(data, /^(id|group|scope|permissions)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteGroupPermission (id) {
    try {
      return await API.graphql({
        query: mutations.deleteGroupPermission,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createGroup (data) {
    try {
      return await API.graphql({
        query: mutations.createGroup,
        variables: this._getFilteredData(data, /^(name|description|precedence)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateGroup (data) {
    try {
      return await API.graphql({
        query: mutations.updateGroup,
        variables: this._getFilteredData(data, /^(name|description|precedence)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteGroup (name) {
    try {
      return await API.graphql({
        query: mutations.deleteGroup,
        variables: { name }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getDomains () {
    try {
      const groupPermissions = await API.graphql({
        query: queries.getDomains
      })
      return {
        items: groupPermissions.data.getDomains
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchDomains ({ from, size, query, sort }) {
    try {
      const domains = await API.graphql({
        query: queries.searchDomains,
        variables: {
          from, size, query, sort
        }
      })
      return domains.data.searchDomains
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createDomain (data) {
    try {
      return await API.graphql({
        query: mutations.createDomain,
        variables: this._getFilteredData(data, /^(name|description)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateDomain (data) {
    try {
      return await API.graphql({
        query: mutations.updateDomain,
        variables: this._getFilteredData(data, /^(id|name|description)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteDomain (id) {
    try {
      return await API.graphql({
        query: mutations.deleteDomain,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getAnnouncements () {
    try {
      const announcements = await API.graphql({
        query: queries.getAnnouncements
      })
      return announcements.data.getAnnouncements
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchAnnouncements ({ from, size, query, sort }) {
    try {
      const announcements = await API.graphql({
        query: queries.searchAnnouncements,
        variables: {
          from, size, query, sort
        }
      })
      return announcements.data.searchAnnouncements
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createAnnouncement (data) {
    try {
      return await API.graphql({
        query: mutations.createAnnouncement,
        variables: this._getFilteredData(data, /^(message|order|enabled)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateAnnouncement (data) {
    try {
      return await API.graphql({
        query: mutations.updateAnnouncement,
        variables: this._getFilteredData(data, /^(id|message|order|enabled)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteAnnouncement (id) {
    try {
      return await API.graphql({
        query: mutations.deleteAnnouncement,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createFaq (data) {
    try {
      return await API.graphql({
        query: mutations.createFaq,
        variables: this._getFilteredData(data, /^(question|answer|category|order)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateFaq (data) {
    try {
      return await API.graphql({
        query: mutations.updateFaq,
        variables: this._getFilteredData(data, /^(id|question|answer|category|order)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteFaq (id) {
    try {
      return await API.graphql({
        query: mutations.deleteFaq,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getFaqs () {
    try {
      const faqs = await API.graphql({
        query: queries.getFaqs
      })
      return {
        items: faqs.data.getFaqs
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchFaqs ({ from, size, query, sort }) {
    try {
      const faqs = await API.graphql({
        query: queries.searchFaqs,
        variables: {
          from, size, query, sort
        }
      })
      return faqs.data.searchFaqs
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createLibraryLink (data) {
    try {
      return await API.graphql({
        query: mutations.createLibraryLink,
        variables: this._getFilteredData(data, /^(linkId|libraryKey)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateLibraryLink (data) {
    try {
      return await API.graphql({
        query: mutations.updateLibraryLink,
        variables: this._getFilteredData(data, /^(id|linkId|libraryKey)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteLibraryLink (id) {
    try {
      return await API.graphql({
        query: mutations.deleteLibraryLink,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getLibraryLinks () {
    try {
      const libraryLinks = await API.graphql({
        query: queries.getLibraryLinks
      })
      return {
        items: libraryLinks.data.getLibraryLinks
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchLibraryLinks ({ from, size, query, sort }) {
    try {
      const libraryLinks = await API.graphql({
        query: queries.searchLibraryLinks,
        variables: {
          from, size, query, sort
        }
      })
      return libraryLinks.data.searchLibraryLinks
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async forgetDevice (deviceKey) {
    try {
      return await API.graphql({
        query: mutations.forgetDevice,
        variables: {
          deviceKey
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async storagePut ({ key, file }) {
    try {
      await Storage.put(key, file)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async storageRemove ({ key }) {
    try {
      await Storage.remove(key)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async storageList (prefix) {
    try {
      const items = await Storage.list(prefix || '')
      for (const i of items) {
        i.url = await Storage.get(i.key)
      }
      return items
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async storageGet (key) {
    try {
      return await Storage.get(key)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async sendDataToActOn (formId, formData) {
    try {
      const res = await API.graphql({
        query: queries.sendDataToActOn,
        variables: {
          formId, formData
        }
      })
      return JSON.parse(res.data.sendDataToActOn)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async sendActOnEmail ({ formExt, emailData }) {
    /*
    try {
      const res = await API.graphql({
        query: mutations.sendActOnEmail,
        variables: {
          formExt,
          emailData
        }
      })
      if (res && res.data && res.data.sendActOnEmail && JSON.parse(res.data.sendActOnEmail).statusCode === 200) {
        return true
      } else {
        // TODO - Add custom error logging if email fails?
      }
      throw new Error(res)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
    */
  }

  async getQuote (id) {
    try {
      const quote = await API.graphql({
        query: queries.getQuote,
        variables: {
          id
        }
      })
      return quote.data.getQuote
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  onPostEventSubscribe (scopeId) {
    const that = this
    const subscription = API.graphql({
      query: subscriptions.onPostEvent,
      variables: {
        scopeId
      }
    }).subscribe({
      async next ({ provider, value }) {
        // specific cognito user event
        if (value.data.onPostEvent.scopeId === that.ctx.store.state.auth.user.username) {
          if (value.data.onPostEvent.eventType === 'GLOBAL_SIGN_OUT') {
            window.location.reload()
          } else if (value.data.onPostEvent.eventType === 'ANNOUNCEMENT_REFRESH') {
            try {
              const announcements = await that.getAnnouncements()
              announcements.sort((a, b) => {
                if (a.order > b.order) {
                  return 1
                } else if (a.order < b.order) {
                  return -1
                }
                return 0
              })
              that.ctx.store.dispatch('announcements/setAnnouncements', announcements)
            } catch (err) {
              throw that.errorHandler(err) || err
            }
          }
        }
      },
      error (err) {
        throw that.errorHandler(err) || err
      }
    })
    this.subscriptions.onPostEvent.push(subscription)
  }

  onUpdateRcDialerOutboundSubscribe () {
    const that = this
    const subscription = API.graphql({
      query: subscriptions.onUpdateRcDialerOutbound
    }).subscribe({
      next: ({ value }) => {
        // Don't apply redirect logic for dialer tabs
        if (/(ambassador-dialer|broker-dialer)/.test(that.ctx.route.name)) {
          return
        }

        // SCCOP-1146 Remove if there will be no issues after deployment
        API.graphql({
          query: mutations.createOutboundResolverLog,
          variables: {
            type: 'debug',
            data: {
              function: 'onUpdateRcDialerOutboundSubscribe',
              userEmail: that.ctx.$auth.email,
              payload: value.data.onUpdateRcDialerOutbound
            }
          }
        })

        const data = JSON.parse(value.data.onUpdateRcDialerOutbound)
        const { quoter, qrn, source, lastHandledBy, isConnected, refreshCallLogger } = data.message
        if (lastHandledBy === that.ctx.$auth.email) {
          if (isConnected) {
            window.location.replace(`/quote/${quoter.toLowerCase()}/${qrn}?source=${source}`)
          } else if (refreshCallLogger) {
            if (window.$nuxt.$store.state.callLogger.miniDrawer) {
              window.$nuxt.$store.dispatch('callLogger/clearCurrentLog')
            } else {
              window.$nuxt.$emit('refresh-call-logger', { apiCallFromRC: true })
            }
          }
        }
      },
      error: (err) => {
        throw that.errorHandler(err) || err
      }
    })
    this.subscriptions.onUpdateRcDialerOutbound.push(subscription)
  }

  async getLeads ({ csrInput, startDate = '', endDate = '' }) {
    try {
      const leads = await API.graphql({
        query: queries.getLeads,
        variables: {
          csrInput,
          startDate,
          endDate
        }
      })
      return JSON.parse(leads?.data?.getLeads)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getLead ({ id, quoter = 'auto', source = 'RATES' }) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: queries.getLead,
        variables: {
          id,
          quoter,
          source
        }
      })
      return JSON.parse(lead?.data?.getLead)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getLeadByQrn ({ qrn, quoter, source, guid }) {
    try {
      const variables = {
        qrn,
        quoter
      }
      if (source) {
        variables.source = source
      }
      if (guid) {
        variables.guid = guid
      }
      const lead = await API.graphql({
        query: queries.getLeadByQrn,
        variables
      })
      return JSON.parse(lead?.data?.getLeadByQrn)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getBrokerList () {
    try {
      const leads = await API.graphql({
        query: queries.getBrokerList
      })
      return JSON.parse(leads?.data?.getBrokerList)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async reIndexBrokerList () {
    try {
      const res = await API.graphql({
        query: mutations.reIndexBrokerList
      })
      return JSON.parse(res?.data?.reIndexBrokerList)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchBrokerList ({ from, size, query, sort }) {
    try {
      const res = await API.graphql({
        query: queries.searchBrokerList,
        variables: {
          from, size, query, sort
        }
      })
      return res?.data?.searchBrokerList
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTransfersBySource (source, payload) {
    try {
      const transfers = await API.graphql({
        query: queries.getTransfersBySource,
        variables: {
          source,
          payload
        }
      })
      return JSON.parse(transfers?.data?.getTransfersBySource)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTransfers (payload) {
    try {
      const transfers = await API.graphql({
        query: queries.getTransfers,
        variables: {
          payload
        }
      })
      return JSON.parse(transfers?.data?.getTransfers)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createBMOData (payload, source) {
    try {
      const transfers = await API.graphql({
        query: mutations.createBMOData,
        variables: {
          payload,
          source
        }
      })
      return JSON.parse(transfers?.data?.createBMOData)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createFintelData (payload, source) {
    try {
      const transfers = await API.graphql({
        query: mutations.createFintelData,
        variables: {
          payload,
          source
        }
      })
      return JSON.parse(transfers?.data?.createFintelData)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getPromoFilters (source) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: queries.getPromoFilters,
        variables: {
          source
        }
      })
      return JSON.parse(lead?.data?.getPromoFilters)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getPromoApplications (filters, source) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: queries.getPromoApplications,
        variables: {
          filters,
          source
        }
      })
      return JSON.parse(lead?.data?.getPromoApplications)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getPromoDisputeApplications (filters, source) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: queries.getPromoDisputeApplications,
        variables: {
          filters,
          source
        }
      })
      return JSON.parse(lead?.data?.getPromoDisputeApplications)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateDisputeStatus (payload, source = 'LR') {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: mutations.updateDisputeStatus,
        variables: {
          payload,
          source
        }
      })
      return JSON.parse(lead?.data?.updateDisputeStatus)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getGiftCardApplications (filters, source) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: queries.getGiftCardApplications,
        variables: {
          filters,
          source
        }
      })
      return JSON.parse(lead?.data?.getGiftCardApplications)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async downloadUnprocessedGiftCards (filters, source, type) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: queries.downloadUnprocessedGiftCards,
        variables: {
          filters,
          source,
          type
        }
      })
      return JSON.parse(lead?.data?.downloadUnprocessedGiftCards)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async confirmGiftCardApplications (data, additionalData, source, type) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: mutations.confirmGiftCardApplications,
        variables: {
          data,
          additionalData,
          source,
          type
        }
      })
      return JSON.parse(lead?.data?.confirmGiftCardApplications)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async downloadCSVFile (filters, source, type) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const lead = await API.graphql({
        query: queries.downloadCSVFile,
        variables: {
          filters,
          source,
          type
        }
      })
      return JSON.parse(lead?.data?.downloadCSVFile)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createTransfer (payload) {
    try {
      const transfer = await API.graphql({
        query: mutations.createTransfer,
        variables: {
          payload
        }
      })
      return JSON.parse(transfer?.data?.createTransfer)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateTransfer (payload) {
    try {
      const res = await API.graphql({
        query: mutations.updateTransfer,
        variables: {
          payload
        }
      })
      return JSON.parse(res?.data?.updateTransfer)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async uploadEzleadsTransfer (source, payload) {
    try {
      const res = await API.graphql({
        query: mutations.uploadEzleadsTransfer,
        variables: {
          source, payload
        }
      })
      return JSON.parse(res?.data?.uploadEzleadsTransfer)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deliverLeadTransfer (source, payload) {
    try {
      const res = await API.graphql({
        query: mutations.deliverLeadTransfer,
        variables: {
          source, payload
        }
      })
      return JSON.parse(res?.data?.deliverLeadTransfer)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async resendEmail ({ leadId, quoter = 'auto', source = 'RATES', email }) {
    if (!(/^(IH|RATES|LR)$/g.test(source))) {
      console.error('Invalid source')
    }
    try {
      const res = await API.graphql({
        query: mutations.resendEmail,
        variables: {
          leadId,
          quoter,
          source,
          email
        }
      })
      return JSON.parse(res?.data?.resendEmail)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteTransfer (transferId, source) {
    try {
      const res = await API.graphql({
        query: mutations.deleteTransfer,
        variables: {
          transferId,
          source
        }
      })
      return JSON.parse(res?.data?.deleteTransfer)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getControlCentreConfig (id) {
    try {
      const config = await API.graphql({
        query: queries.getControlCentreConfig,
        variables: {
          id
        }
      })
      return config.data.getControlCentreConfig
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getAllControlCentreConfig () {
    try {
      const config = await API.graphql({
        query: queries.getAllControlCentreConfig
      })
      return config.data.getAllControlCentreConfig
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchControlCentreConfig ({ from, size, query, sort }) {
    try {
      const config = await API.graphql({
        query: queries.searchControlCentreConfig,
        variables: {
          from,
          size,
          query,
          sort
        }
      })
      return config.data.searchControlCentreConfig
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createControlCentreConfig ({ scope, json }) {
    try {
      const res = await API.graphql({
        query: mutations.createControlCentreConfig,
        variables: {
          scope,
          json
        }
      })
      return JSON.parse(res?.data?.createControlCentreConfig)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async lockControlCentreConfig (id) {
    try {
      const res = await API.graphql({
        query: mutations.lockControlCentreConfig,
        variables: {
          id
        }
      })
      return JSON.parse(res?.data?.lockControlCentreConfig)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateControlCentreConfig (id, scope, json, queryLimits) {
    try {
      const res = await API.graphql({
        query: mutations.updateControlCentreConfig,
        variables: {
          id,
          scope,
          json,
          queryLimits
        }
      })
      return JSON.parse(res?.data?.updateControlCentreConfig)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getExcludeOutbound () {
    try {
      const res = await API.graphql({
        query: queries.getExcludeOutbound
      })
      return res.data.getExcludeOutbound
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchExcludeOutbound ({ from, size, query, sort }) {
    try {
      const res = await API.graphql({
        query: queries.searchExcludeOutbound,
        variables: {
          from, size, query, sort
        }
      })
      return res.data.searchExcludeOutbound
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createExcludeOutbound ({ type, value }) {
    try {
      return await API.graphql({
        query: mutations.createExcludeOutbound,
        variables: {
          type,
          value
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateExcludeOutbound ({ id, type, value }) {
    try {
      return await API.graphql({
        query: mutations.updateExcludeOutbound,
        variables: {
          id,
          type,
          value
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteExcludeOutbound (id) {
    try {
      return await API.graphql({
        query: mutations.deleteExcludeOutbound,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchOutbound ({ from, size, query, sort }) {
    try {
      const res = await API.graphql({
        query: queries.searchOutbound,
        variables: {
          from, size, query, sort
        }
      })
      return res.data.searchOutbound
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchRealtimeOutboundLeads (quoter, startDateTime, endDateTime) {
    try {
      const res = await API.graphql({
        query: queries.searchRealtimeOutboundLeads,
        variables: { quoter, startDateTime, endDateTime }
      })
      return res?.data?.searchRealtimeOutboundLeads
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchOutboundByKeyValue (key, value) {
    try {
      const res = await API.graphql({
        query: queries.searchOutboundByKeyValue,
        variables: {
          key, value
        }
      })
      return JSON.parse(res?.data?.searchOutboundByKeyValue)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getBubblePrioritySort (prioritySettings) {
    try {
      const res = await API.graphql({
        query: queries.getBubblePrioritySort,
        variables: {
          prioritySettings
        }
      })
      return JSON.parse(res?.data?.getBubblePrioritySort)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async reassignLeadOutbound ({ id, lastHandledBy, callbackTime }) {
    try {
      const res = await API.graphql({
        query: mutations.reassignLeadOutbound,
        variables: {
          id, lastHandledBy, callbackTime
        }
      })
      return JSON.parse(res?.data?.reassignLeadOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async addLatestCallLogToOutbound ({ qrn, source, attemptFilter }) {
    try {
      const res = await API.graphql({
        query: mutations.addLatestCallLogToOutbound,
        variables: {
          qrn, source, attemptFilter
        }
      })
      return JSON.parse(res?.data?.addLatestCallLogToOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async changeOutboundLeadQrn ({ qrnUpdated, qrnOriginal, source }) {
    try {
      const res = await API.graphql({
        query: mutations.changeOutboundLeadQrn,
        variables: {
          qrnUpdated, qrnOriginal, source
        }
      })
      return JSON.parse(res?.data?.changeOutboundLeadQrn)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async closeOutboundLeads ({ qrn, source, quoter, leadId }) {
    try {
      const res = await API.graphql({
        query: mutations.closeOutboundLeads,
        variables: {
          qrn, source, quoter, leadId
        }
      })
      return JSON.parse(res?.data?.closeOutboundLeads)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async reinsertLeadToOutbound ({ qrn, source, callbackTime, lastHandledBy, attemptFilter }) {
    try {
      const res = await API.graphql({
        query: mutations.reinsertLeadToOutbound,
        variables: {
          qrn, source, callbackTime, lastHandledBy, attemptFilter
        }
      })
      return JSON.parse(res?.data?.reinsertLeadToOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async cancelOutboundCallLog ({ id, qrn, source, attempt }) {
    try {
      const res = await API.graphql({
        query: mutations.cancelOutboundCallLog,
        variables: {
          id, qrn, source, attempt
        }
      })
      return JSON.parse(res?.data?.cancelOutboundCallLog)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async removeLeadFromOutbound (payload) {
    try {
      const res = await API.graphql({
        query: mutations.removeLeadFromOutbound,
        variables: {
          payload
        }
      })
      return JSON.parse(res?.data?.removeLeadFromOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async imFeelingLucky (searchQuery) {
    try {
      const res = await API.graphql({
        query: mutations.imFeelingLucky,
        variables: {
          searchQuery
        }
      })
      return JSON.parse(res?.data?.imFeelingLucky)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async syncLatestCallLogToOutbound ({ id, attempts, qrn, source }) {
    try {
      const res = await API.graphql({
        query: mutations.syncLatestCallLogToOutbound,
        variables: {
          id, attempts, qrn, source
        }
      })
      return JSON.parse(res?.data?.syncLatestCallLogToOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async syncAttemptsOutbound (qrn) {
    try {
      const res = await API.graphql({
        query: mutations.syncAttemptsOutbound,
        variables: {
          qrn
        }
      })
      return JSON.parse(res?.data?.syncAttemptsOutbound)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  // eslint-disable-next-line camelcase
  async searchCallLogs ({ from, size, query, sort, search_after }) {
    try {
      const res = await API.graphql({
        query: queries.searchCallLogs,
        variables: {
          from, size, query, sort, search_after
        }
      })
      return res?.data?.searchCallLogs
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createCallLog (payload) {
    try {
      const res = await API.graphql({
        query: mutations.createCallLog,
        variables: {
          ...payload
        }
      })
      return JSON.parse(res?.data?.createCallLog)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateCallLog (payload) {
    try {
      const res = await API.graphql({
        query: mutations.updateCallLog,
        variables: {
          ...payload
        }
      })
      return JSON.parse(res?.data?.updateCallLog)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteCallLog (id, attempt) {
    try {
      return await API.graphql({
        query: mutations.deleteCallLog,
        variables: {
          id, attempt
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async refreshCallLogIndex (id, attempt) {
    try {
      await API.graphql({
        query: mutations.refreshCallLogIndex,
        variables: {
          id, attempt
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getAgentDetails () {
    try {
      const res = await API.graphql({
        query: queries.getAgentDetails
      })
      return JSON.parse(res?.data?.getAgentDetails)
    } catch (err) {
      console.error(err)
    }
  }

  async getActivePhoneCall (agentId) {
    try {
      if (!agentId) {
        agentId = this.ctx.store.state?.callLogger?.agent?.agentId || ''
      }
      const res = await API.graphql({
        query: queries.getActivePhoneCall,
        variables: { agentId }
      })
      return JSON.parse(res?.data?.getActivePhoneCall)
    } catch (err) {
      console.error(err)
    }
  }

  async startPhoneCall ({ agentId, sessionId, phoneNumber, brand }) {
    try {
      const res = await API.graphql({
        query: mutations.startPhoneCall,
        variables: {
          agentId, sessionId, phoneNumber, brand
        }
      })
      return JSON.parse(res?.data?.startPhoneCall)
    } catch (err) {
      console.error(err)
    }
  }

  async endPhoneCall ({ sessionId, agentId }) {
    try {
      const res = await API.graphql({
        query: mutations.endPhoneCall,
        variables: { sessionId, agentId }
      })
      return JSON.parse(res?.data?.endPhoneCall)
    } catch (err) {
      console.error(err)
    }
  }

  async createJsonLog ({ scope, event, source, log }) {
    try {
      const res = await API.graphql({
        query: mutations.createJsonLog,
        variables: {
          scope,
          event,
          source,
          log: JSON.stringify({ ...log, version: this.ctx.$config.VERSION })
        }
      })
      return JSON.parse(res?.data?.createJsonLog)
    } catch (err) {
      console.error(err)
    }
  }

  async searchLeadGroupsByCsr (query) {
    try {
      const res = await API.graphql({
        query: queries.searchLeadGroupsByCsr,
        variables: {
          query
        }
      })
      return res?.data?.searchLeadGroupsByCsr
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTrustPilotInvitationLink (source, data) {
    try {
      const res = await API.graphql({
        query: queries.getTrustPilotInvitationLink,
        variables: {
          source, data
        }
      })
      return JSON.parse(res.data.getTrustPilotInvitationLink)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async completeQualtricsQuoteEvent (data) {
    try {
      const res = await API.graphql({
        query: mutations.completeQualtricsQuoteEvent,
        variables: {
          data
        }
      })
      return JSON.parse(res.data.completeQualtricsQuoteEvent)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTransferSessionAttributes (agentId) {
    try {
      const res = await API.graphql({
        query: queries.getTransferSessionAttributes,
        variables: { agentId }
      })
      return JSON.parse(res.data.getTransferSessionAttributes)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchSuppliers ({ from, size, query, sort }) {
    try {
      const res = await API.graphql({
        query: queries.searchSuppliers,
        variables: {
          from, size, query, sort
        }
      })
      return res.data.searchSuppliers
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async reIndexSupplierList () {
    try {
      const res = await API.graphql({
        query: mutations.reIndexSupplierList
      })
      return JSON.parse(res?.data?.reIndexSupplierList)
    } catch (err) {
      console.error(err)
    }
  }

  async createTransferSessionAttributes ({ agentId, quoteRefId, transferId, source, quoter }) {
    try {
      const res = await API.graphql({
        query: mutations.createTransferSessionAttributes,
        variables: {
          agentId, quoteRefId, transferId, source, quoter
        }
      })
      return JSON.parse(res.data.createTransferSessionAttributes)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createUnfulfilledLead ({ data, source }) {
    try {
      const res = await API.graphql({
        query: mutations.createUnfulfilledLead,
        variables: {
          data, source
        }
      })
      return JSON.parse(res?.data?.createUnfulfilledLead)
    } catch (err) {
      console.error(err)
    }
  }

  async getRcDialerOutbound () {
    try {
      const res = await API.graphql({
        query: queries.getRcDialerOutbound
      })
      return JSON.parse(res?.data?.getRcDialerOutbound)
    } catch (err) {
      console.error(err)
    }
  }

  async reinsertFetchedByRcLeadsToOutbound () {
    try {
      const res = await API.graphql({
        query: mutations.reinsertFetchedByRcLeadsToOutbound
      })
      return JSON.parse(res?.data?.reinsertFetchedByRcLeadsToOutbound)
    } catch (err) {
      console.error(err)
    }
  }

  errorHandler (err) {
    if ((typeof err === 'string' && err.match(/No current user|Cannot retrieve a new session/)) ||
        (typeof err === 'object' && err.code && err.code.match(/NotAuthorizedException/))) {
      // kick to login page
      this.ctx.store.dispatch('auth/logout')
      this.ctx.redirect({ name: `login___${this.ctx.app.i18n.locale}` })
    } else {
      // if err is not associated to session timeout, no user, etc. throw it back up to the caller
      return err
    }
  }
}

export default (context, inject) => {
  const apiService = new ApiService(context)
  context.$api = Vue.prototype.$api = Vue.$api = apiService
  inject('api', apiService)
}
